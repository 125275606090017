import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { sweetError } from '../functions/sweetAlert';
import * as FaIcons from 'react-icons/fa';

const SignatureArea = ({ save, close }) => {
    const sigRef = useRef(null);

    const trimSignature = () => {
        if (!sigRef.current.isEmpty()) {
            save(sigRef.current.getTrimmedCanvas().toDataURL('image/png'));
            close();
        } else {
            sweetError('Debes colocar la firma');
        }
    };

    const clearSignature = () => {
        sigRef.current.clear();
    };

    return (
        <div className="signature-area">
            <h4 className="tac">Firma en cualquier área</h4>
            <SignatureCanvas
                canvasProps={{ style: { borderStyle: 'solid', height: '100%', width: '100%' } }}
                ref={sigRef}
            />

            <FaIcons.FaCheckCircle className="confirm-signature-icon" onClick={trimSignature} />
            <FaIcons.FaEraser className="eraser-signature-icon" onClick={clearSignature} />
        </div>
    );
};

export default SignatureArea;
