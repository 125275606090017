import React, { useEffect, useState } from 'react';
import { Countries, sessionTypes } from '../../constants/data';
import { allData, getData, handleError, postData, putData } from '../../functions/services';
import Loader from '../common/Loader';
import useForm from '../../hooks/useForm';
import { formatDateToAmerican } from '../../functions/dates';
import { getCitiesByCountry } from '../../functions/Countries';
import { arrayByNameAsc } from '../../functions/general';

const CountriesData = arrayByNameAsc(Countries);

const ModalSession = (props) => {
    const { data, sessionId } = props;

    const {
        state,
        handleChange,
        handleChangeByName,
        handleMultiChange,
        handleCheckIdList,
    } = useForm({
        country: data ? data.country : CountriesData[0].name,
        city: data ? data.city : getCitiesByCountry(CountriesData[0].name)[0].name,
        date: data ? formatDateToAmerican(data.date) : '',
        userId: data ? data.userId : '',
        hourIn: data ? data.hourIn : '00:00',
        hourOut: data ? data.hourOut : '00:00',
        sessionType: data ? data.sessionType : sessionTypes[0],
        goal: data ? data.goal : '',
        participantsNumber: data ? data.participantsNumber : 1,
        sessionId: sessionId,
        teamId: data ? data.teamId : '',
        checkedMentees: data ? data.mentees.map((val) => val.id) : [],
    });

    const [coachs, setCoachs] = useState([]);
    const [teams, setTeams] = useState([]);
    const [mentees, setMentees] = useState([]);
    const [cities, setCities] = useState(
        data ? getCitiesByCountry(data.country) : getCitiesByCountry(CountriesData[0].name)
    );
    const [loading, setLoading] = useState(true);

    const loadData = async () => {
        try {
            const req = await allData([
                getData('users/coachs'),
                getData('teams'),
                getData('mentees'),
            ]);

            setCoachs(req[0].data);
            setTeams(req[1].data);
            setMentees(req[2].data);

            if (!data) {
                const stateData = {
                    userId: req[0].data[0].id,
                    teamId: req[1].data[0].id,
                };
                handleMultiChange(stateData);
            }

            setLoading(false);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (!loading) {
            const citiesData = getCitiesByCountry(state.country);
            setCities(citiesData);
            handleChangeByName('city', citiesData[0].name);
        }
    }, [state.country]);

    const create = async () => {
        setLoading(true);

        try {
            await postData('sessionItems', state);
            props.close();
            props.loadTable();
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const edit = async () => {
        setLoading(true);

        try {
            await putData('sessionItems', data.id, state);
            props.close();
            props.loadTable();
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (data) {
            edit();
        } else {
            create();
        }
    };

    const countriesList = () => {
        return arrayByNameAsc(Countries).map((val, i) => (
            <option value={val.name} key={i}>
                {val.name}
            </option>
        ));
    };

    const citiesList = () => {
        return arrayByNameAsc(cities).map((val, i) => (
            <option value={val.name} key={i}>
                {val.name}
            </option>
        ));
    };

    const coachsList = () => {
        return coachs.map((val, i) => (
            <option value={val.id} key={i}>
                {val.name}
            </option>
        ));
    };

    const teamsList = () => {
        return teams.map((val, i) => (
            <option value={val.id} key={i}>
                {val.name}
            </option>
        ));
    };

    const sessionTypeList = () => {
        return sessionTypes.map((val, i) => (
            <option value={val} key={i}>
                {val}
            </option>
        ));
    };

    const menteesList = () => {
        return mentees.map((val) => (
            <div className="col-3 tac" key={val.id}>
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="checkedMentees"
                        checked={state.checkedMentees.includes(val.id)}
                        onChange={handleCheckIdList}
                        value={val.id}
                    />
                    <label className="form-check-label">{val.name}</label>
                </div>
            </div>
        ));
    };

    return (
        <div>
            <div className="p-2 modal-title">Sesión</div>

            {loading ? (
                <div className="center-admin-loader-modal">
                    <Loader />
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="form-row mt-3">
                        <div className="col-6">
                            <div className="form-group">
                                <label>País</label>
                                <select
                                    name="country"
                                    className="form-control"
                                    value={state.country}
                                    onChange={(e) => handleChange(e)}
                                    required
                                >
                                    {countriesList()}
                                </select>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Ciudad</label>
                                <select
                                    name="city"
                                    className="form-control"
                                    value={state.city}
                                    onChange={handleChange}
                                    required
                                >
                                    {citiesList()}
                                </select>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Fecha</label>
                                <input
                                    type="date"
                                    name="date"
                                    className="form-control"
                                    value={state.date}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Coach/Mentor</label>
                                <select
                                    name="userId"
                                    className="form-control"
                                    value={state.userId}
                                    onChange={handleChange}
                                    required
                                >
                                    {coachsList()}
                                </select>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Hora de inicio</label>
                                <input
                                    type="time"
                                    name="hourIn"
                                    className="form-control"
                                    value={state.hourIn}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Hora de salida</label>
                                <input
                                    type="time"
                                    name="hourOut"
                                    className="form-control"
                                    value={state.hourOut}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Tipo de sesión</label>
                                <select
                                    name="sessionType"
                                    className="form-control"
                                    value={state.sessionType}
                                    onChange={handleChange}
                                    required
                                >
                                    {sessionTypeList()}
                                </select>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Área</label>
                                <select
                                    name="teamId"
                                    className="form-control"
                                    value={state.teamId}
                                    onChange={handleChange}
                                    required
                                >
                                    {teamsList()}
                                </select>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Cantidad de participantes</label>
                                <input
                                    type="number"
                                    name="participantsNumber"
                                    className="form-control"
                                    value={state.participantsNumber}
                                    onChange={handleChange}
                                    min={1}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label>Objetivo</label>
                                <textarea
                                    name="goal"
                                    className="form-control"
                                    value={state.goal}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <h5 className="tac mt-3">Coachees/Mentees</h5>

                    <div className="row my-3">{menteesList()}</div>

                    <button className="btn btn-primary" type="submit" disabled={loading}>
                        Guardar
                    </button>
                </form>
            )}
        </div>
    );
};

export default ModalSession;
