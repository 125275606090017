import { format } from 'date-fns';
import React from 'react';
import logo from '../assets/images/logo.JPG';
import { contractTypeVal } from '../constants/data';
import { getTotalMen, getTotalPagoUni } from '../functions/contract';

const ContractPreview = (props) => {
    const { contract } = props;
    const date = contract ? format(new Date(contract.createdAt), 'dd-MM-yyyy') : '';
    const listDetails = contract ? contract.contractDetails : [];
    const listDevices = contract ? contract.contractDevices : [];

    const getVigencia = () => {
        if (contract.contractType === contractTypeVal.ConPermanencia) {
            return `${contract.arriVig} MESES`;
        } else {
            return 'Indefinida';
        }
    };

    return (
        <div>
            <div className="tac my-5">
                <h4>Preview del contrato</h4>
            </div>

            <div className="row">
                <div className="col-6">
                    <table className="table mt-3 contract-table table-responsive-md">
                        <thead>
                            <tr>
                                <th>N. Contrato</th>
                                <th>Fecha</th>
                                <th>Permanencia</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{contract.id}</td>
                                <td>{date}</td>
                                <td>{getVigencia()}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="col-6">
                    <img className="float-right" src={logo} alt="logo" />
                </div>
            </div>

            <div className="tac mb-3 font-weight-bold">
                CONTRATO DE SUMINISTRO DE ACCESO A SERVICIO DE PLATAFORMA
            </div>

            <table className="table contract-table table-responsive-md">
                <thead>
                    <tr>
                        <th>1-IDENTIFICACIÓN DEL CLIENTE</th>
                        <th>TIPO DE CLIENTE:</th>
                        <th>{contract.clientType}</th>
                    </tr>
                </thead>
            </table>

            <table className="table tableUp contract-table">
                <thead>
                    <tr>
                        <th>RAZÓN SOCIAL O NOMBRE Y APELLIDOS (SEGÚN CORRESPONDA):</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{contract.clientName}</td>
                    </tr>
                </tbody>
            </table>

            <table className="table tableUp contract-table table-responsive-md">
                <thead>
                    <tr>
                        <th>DIRECCIÓN</th>
                        <th>COMUNA</th>
                        <th>CIUDAD</th>
                        <th>TELÉFONO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{contract.clientDir}</td>
                        <td>{contract.clientComu}</td>
                        <td>{contract.clientCity}</td>
                        <td>{contract.clientTel}</td>
                    </tr>
                </tbody>
            </table>

            <table className="table tableUp contract-table table-responsive-md">
                <thead>
                    <tr>
                        <th>GIRO</th>
                        <th>RUT</th>
                        <th>EMAIL DE CONTACTO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{contract.clientGiro}</td>
                        <td>{contract.clientRut}</td>
                        <td>{contract.clientEmail}</td>
                    </tr>
                </tbody>
            </table>

            <table className="table tableUp contract-table">
                <thead>
                    <tr>
                        <th>
                            2. IDENTIFICACIÓN DEL REPRESENTANTE LEGAL O PERSONA DE CONTACTO O SEGÚN
                            CORRESPONDA)
                        </th>
                    </tr>
                </thead>
            </table>

            <table className="table tableUp contract-table table-responsive-md">
                <thead>
                    <tr>
                        <th>NOMBRE COMPLETO</th>
                        <th>RUT</th>
                        <th>EMAIL DE CONTACTO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{contract.repName}</td>
                        <td>{contract.repRut}</td>
                        <td>{contract.repEmail}</td>
                    </tr>
                </tbody>
            </table>

            <table className="table tableUp contract-table table-responsive-md">
                <thead>
                    <tr>
                        <th>TELÉFONO 1</th>
                        <th>TELÉFONO 2</th>
                        <th>EMAIL DE FACTURACION ELECTRÓNICA</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{contract.repTel1}</td>
                        <td>{contract.repTel2}</td>
                        <td>{contract.repFacEmail}</td>
                    </tr>
                </tbody>
            </table>

            <table className="table tableUp contract-table">
                <thead>
                    <tr>
                        <th className="tac">OBSERVACIONES</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{contract.repObs}</td>
                    </tr>
                </tbody>
            </table>

            <div className="tac mb-3 font-weight-bold">DETALLES</div>

            <table className="table contract-table table-responsive-md">
                <thead>
                    <tr>
                        <th>CANT</th>
                        <th>EQUIPO</th>
                        <th>MODELO</th>
                        <th>INSTALACIÓN</th>
                        <th>VENTA</th>
                        <th>ARRIENDO</th>
                        <th>ACTIVACIÓN</th>
                        <th>PLAN</th>
                        <th>SEGURO</th>
                    </tr>
                </thead>
                <tbody>
                    {listDetails.map((det, i) => (
                        <tr key={i}>
                            <td>{parseInt(det.cantidad)}</td>
                            <td>{det.equipo}</td>
                            <td>{det.modelo}</td>
                            <td>{det.instala}</td>
                            <td>{det.venta}</td>
                            <td>{det.arrien}</td>
                            <td>{det.actv}</td>
                            <td>{det.plan}</td>
                            <td>{det.seguro}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <table className="table tableUp contract-table">
                <thead>
                    <tr>
                        <th className="tac">TOTAL PAGO ÚNICO</th>
                        <th className="tac">TOTAL MENSUAL PLAN</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{getTotalPagoUni(listDetails)}</td>
                        <td>{getTotalMen(listDetails)}</td>
                    </tr>
                </tbody>
            </table>

            <table className="table tableUp contract-table">
                <thead>
                    <tr>
                        <th className="tac">OBSERVACIONES</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{contract.detObs}</td>
                    </tr>
                </tbody>
            </table>

            <div className="tac mb-3 font-weight-bold">
                CONDICIONES GENERALES Y TERMINOS DEL SERVICIO
            </div>

            <p>
                Las presentes condiciones generales y todos sus anexos, actuales o futuros,
                constituyen las estipulaciones del Contrato de Suministro de Servicio de Plataforma
                ("el contrato" o "el contrato de suministro"), en virtud de las cuales SERVICIOS
                INTEGRALES TECHPA SPA, RUT 76.552.663-9 ("TECHPA") suministrará, a la persona
                natural o jurídica individualizada en el Contrato ("el SUSCRIPTOR" o "el CLIENTE"),
                el servicio y los servicios adicionales especificados.
            </p>

            <p>
                "El Servicio" permitirá al CLIENTE interactuar con la(s) plataforma(s) de monitoreo
                y módulos especializados que TECHPA tiene disponible. Asimismo, en caso de que el
                CLIENTE contrate un servicio de datos tendrá acceso a datos móviles, en las
                condiciones pactadas. Adicionalmente, sujeto a disponibilidad, el SUSCRIPTOR podrá
                acceder al servicio de roaming internacional, de acuerdo a las condiciones vigentes
                al momento de la solicitud y en función de las disponibilidades futuras por parte de
                las TELCO (con el costo respectivo, si es el caso).
            </p>

            <p>
                Los planes de datos sólo garantizan la navegación en las zonas de cobertura según el
                plan contratado, asi como también, en función de la disponibilidad dinámica que las
                diferentes TELCO entreguen en un momento dado. Techpa no se hace responsable por la
                cobertura y/o fallas externas que impidan el normal tráfico de datos.
            </p>

            <p>
                <strong>1.Del Pago:</strong> El Cliente es responsable del pago de todos los
                servicios contratados, según las tarifas del Plan Tarifario seleccionado por el
                Cliente en el Contrato, así como a los demás precios de todos los servicios
                solicitados o devengados. El servicio de roaming internacional de datos, y servicios
                especiales, serán facturados con cargos adicionales, en caso de que el suscriptor no
                tenga contratado un Plan Internacional. El Cliente es responsable de informarse del
                precio y tarifas de estos servicios antes de utilizarlos, y será responsable del
                pago íntegro de todos los servicios contratados/utilizados.
            </p>

            <p>
                El Cliente deberá pagar mensualmente la totalidad del monto de dinero consignado en
                el documento de cobro correspondiente, hasta la fecha de su vencimiento, que será en
                todos los casos a los 15 días corridos siguientes a su emisión, en los lugares y en
                la forma que señala la misma. El Cliente declara conocer la fecha de vencimiento de
                su cuenta, razón por la cual, en caso de no recibir oportunamente dicha cuenta, se
                obliga a efectuar su pago dentro de plazo. En caso de mora o simple retardo en el
                pago del documento de cobro correspondiente, TECHPA quedará facultada para aplicar
                al monto adeudado, el máximo interés convencional permitido por la ley, desde la
                fecha en que se hizo exigible la obligación y hasta el pago efectivo, y hacer
                efectiva la suspensión de servicio correspondiente.
            </p>

            <p>
                El no pago del documento de cobro correspondiente, facultará a TECHPA a suspender
                los accesos al/los servicio contratados, como de cualquier otro servicio que se
                preste a dicho Cliente, luego de 5 días de cumplida la fecha de vencimiento
                establecida para su pago. El corte y reposición del servicio quedarán afectos a
                cobro por parte de TECHPA.
            </p>

            <p>
                <strong>2.Servicio de Asistencia Técnica, Comercial y Reclamos:</strong> El Cliente
                podrá acceder a los servicios de asistencia técnica, asistencia comercial y
                reclamos. Para ello, deberá utilizar los conductos regulares dispuestos por la
                empresa, o en su defecto a su Asesor de Cuenta, quien canalizará todo requerimiento
                del Cliente.
            </p>

            <p>
                <strong>3.Requisitos Indispensables para la Contratación:</strong>El Cliente deberá
                disponer de un equipo rastreador GPS que deberá cumplir las características técnicas
                establecidas por TECHPA. Así mismo, deberá entregar la documentación requerida, la
                cual será solicitada por el Área Comercial de Techpa.
            </p>

            <p>
                <strong>
                    4.Cambio de Plan Tarifario, Datos del Cliente y Cambio de Líneas a Solicitud del
                    Cliente:{' '}
                </strong>
                El Cliente podrá cambiar su Plan Tarifario por uno disponible comercialmente,
                debiendo informar por escrito a TECHPA, el que entrará en vigencia a partir del
                ciclo siguiente de facturación. Asimismo, el Cliente podrá solicitar la modificación
                de sus datos personales, acompañando la documentación fehaciente necesaria para
                dicha modificación.
            </p>

            <p>
                <strong>5.Exclusiones:</strong>
            </p>

            <p>
                a.TECHPA no garantiza la disponibilidad del servicio, en el caso de que el Cliente
                haya introducido en su equipo, elementos de hardware o de software que impida su
                normal funcionamiento.
            </p>

            <p>
                b.TECHPA no será responsable de la seguridad de la información residente en el
                equipo del Cliente, por cuanto este (equipo) se encuentra en poder de cada cliente,
                impidiendo el resguardo más allá de los alcances de programación. Lo anterior libera
                de toda responsabilidad a TECHPA de los perjuicios y daño que pudiera afectar al
                Cliente en tal sentido.
            </p>

            <p>
                c.TECHPA no garantiza la disponibilidad del servicio, por situaciones ajenas a su
                responsabilidad.
            </p>

            <p>
                <strong>6.Cesión de Contrato:</strong> La calidad de Cliente sólo podrá cederse en
                favor de un tercero, previa autorización escrita de TECHPA, debiendo el Cliente
                estar al día en todas sus obligaciones, sin perjuicio de quedar obligado al pago de
                todo saldo por prestaciones devengadas y no pagadas al momento de la cesión.
                Aceptada la cesión, ésta sólo se perfeccionará una vez que el cesionario complete y
                suscriba un nuevo contrato.
            </p>

            <p>
                <strong>7.Duración del Contrato:</strong>El plazo de vigencia de este contrato es
                indefinido, y se inicia con la activación del servicio de cada equipo AVL, pudiendo
                tener el cliente diferentes fechas de vigencia, en función de la activación
                individual de cada equipo AVL. El Cliente podrá poner término al contrato de
                suministro, con 60 días de anticipación, conforme a la normativa vigente,
                solicitándolo a TECHPA, por sí o a través de mandatario debidamente autorizado.
                TECHPA procederá al término solicitado en el plazo de 60 días a contar del
                requerimiento. El termino unilateral y anticipada del contrato por el Cliente no lo
                libera, del pago de todos los cargos devengados hasta el momento de término.
            </p>

            <p>
                <strong>8.Duración del Contrato Especial:</strong> En los casos en los cuales el
                cliente opte por alguna modalidad de planes de servicios con clausulas especiales,
                que incluyan financiamiento de implementación por parte de TECHPA, ya sea en
                equipos, accesorios y/o desarrollos, o arriendo de equipos y/o accesorios, el
                Cliente se obliga a pagar la renta mensual por la duración del contrato pactada.
            </p>

            <p>
                <strong>8.1 Termino de Contrato:</strong>El Cliente podrá retractarse en cualquier
                momento o poner término anticipado a la permanencia pactada a través del método de
                Contrato Especial, con 60 días de anticipación, evento en el cual, se entenderá
                terminado el presente contrato y el Cliente podrá elegir una de las siguientes
                opciones:
            </p>

            <p>a.Continuar pagando mensualmente las rentas mensuales faltantes.</p>

            <p>
                b.Pagar anticipadamente el total de las rentas mensuales pendientes de pago hasta
                completar la totalidad de las cuotas comprometidas, situación en la cual podrá optar
                a un descuento por pronto pago a acordar con el cliente.
            </p>

            <p>
                <strong>9.Devolución de Equipos AVL:</strong>Existiendo un término de contrato o
                servicio, ya sea total o parcial, el Cliente se obliga a la devolución del/los
                Equipos AVL y accesorios en modalidad de arriendo, para ello, tendrá 2 opciones:
            </p>

            <p>
                a.Realizar el retiro de manera independiente, tanto de los Equipos AVL como sus
                accesorios y hacerlos llegar dentro de 5 días hábiles a las oficinas de TECHPA.
            </p>

            <p>
                b.Solicitar el retiro de los Equipos AVL y sus accesorios, previo pago de 1 UF + IVA
                por Equipo AVL. Dicho pago deberá ser cancelado dentro de los 5 días hábiles de la
                fecha de término efectivo del servicio. Lo anterior, sin perjuicio de un eventual
                descuento o eliminación del cargo por parte de TECHPA hacia el Cliente.
            </p>

            <p>
                La no devolución o pago del retiro del/los Equipos AVL y sus accesorios según
                corresponda, facultará a TECHPA a facturar y exigir el pago de la totalidad del
                valor del/los equipos AVL y/o accesorios, según tabla de valor individualizado en el
                presente Contrato, apartado IV Equipos, Punto 3: Valor de Equipos AVL y accesorios.
            </p>

            <p>
                <strong>10.Término del Contrato de Servicios:</strong>El Cliente y TECHPA acuerdan
                expresamente que esta última podrá poner término inmediato al presente contrato, en
                los siguientes casos:
            </p>

            <p>
                a. En caso de no pago del documento de cobro correspondiente, transcurridos 90 días
                corridos siguientes a la fecha de vencimiento del correspondiente documento de
                cobro.
            </p>

            <p>
                b. Si el Cliente hiciere directamente o de cualquier forma uso indebido o ilegal del
                servicio o del equipo, asociado a numeración suministrada por TECHPA, o ponga en
                riesgo la red de ésta y su red de acceso o utilizare algún equipo no autorizado por
                TECHPA.
            </p>

            <p>
                c.Si es solicitada la declaración de quiebra del Cliente o éste formula
                proposiciones de convenio judicial o prejudicial, o si se encuentra en estado de
                notoria insolvencia.
            </p>

            <p>
                d.Por muerte o extinción de la personalidad jurídica del Cliente, según corresponda.
            </p>

            <p>
                e.Si los datos proporcionados y acompañados por el Cliente fueren falsos, inexactos,
                equívocos, erróneos o disconformes.
            </p>

            <p>
                f.Por infracción de cualquiera otra obligación que eI Cliente haya contraído
                conforme al presente contrata de suministro.
            </p>

            <p>
                Ejercida por TECHPA la facultad señalada, informará este hecho al Cliente, perdiendo
                éste su calidad de tal y quedando obligado al pago de todas las sumas que a la fecha
                de terminación adeudare a TECHPA. Así mismo, deberá hacer llegar los equipos AVL y
                sus accesorios (Solo en caso de Arriendo), dentro de los siguientes 5 días hábiles a
                las oficinas de Techpa. Si esto no se cumpliese, Techpa queda facultado para
                facturar y exigir el pago del valor del/los equipo AVL y/o accesorios, según tabla
                de valor individualizado en el presente Contrato, apartado IV Equipos, Punto 3:
                Valor de Equipos AVL y accesorios.
            </p>

            <p>
                <strong>11.Intervenciones:</strong>
            </p>

            <p>
                a.Toda intervención NO AUTORIZADA a los equipos AVL, ya sea física o virtualmente,
                que ocasionen o no, fallas en el/los equipo AVL, estará sujeta a una multa de 3 UF +
                IVA por evento, por equipo, la cual será facturada en el próximo ciclo de
                facturación.
            </p>

            <p>
                b.Toda intervención AUTORIZADA por TECHPA, que genere fallas en el equipo o en su
                trazabilidad, será responsabilidad de TECHPA, no existiendo costo alguno para el
                cliente.
            </p>

            <p>
                <strong>12.Servicio de Facturación y Detalle:</strong>
            </p>

            <p>
                a.La Facturación de los Servicios mensuales contratados por el Cliente, será el
                primer día hábil del mes en curso, con fecha de vencimiento a los 15 días corridos
                desde la emisión del documento.
            </p>

            <p>
                b.La Facturación de los Servicios al momento de la incorporación de un nuevo
                cliente, o anexo a un contrato existente, se realizará el día de alta del Servicio
                Contratado o Anexado y generará un cobro proporcional, con fecha de vencimiento a
                los 5 días corridos desde la emisión del documento.
            </p>

            <p>
                c.La Facturación de los Servicios al momento del termino de contrato, parcial o
                total, se realizará el mismo día de la solicitud, con fecha de vencimiento a los 5
                días corridos desde la emisión del documento.
            </p>

            <p>
                d.El Cliente en cualquier momento podrá solicitar la facturación detallada de su
                servicio, a través de los niveles de atención comercial. Efectuada la solicitud
                TECHPA dispondrá de 5 días hábiles para su despacho al Cliente. El Cliente podrá, en
                cualquier momento, acceder al nivel de atención comercial para la verificación de su
                consumo.
            </p>

            <p>
                <strong>13. Tratamiento de Datos Personales del Cliente:</strong>
                Los datos personales proporcionados por el Cliente sólo serán utilizados para los
                fines asociados a la prestación del servicio, y su tratamiento será efectuado
                conforme a lo previsto en la Ley N° 19.628.
            </p>

            <p>
                <strong>14. Facturación Electrónica:</strong>
                El Cliente, autoriza a SERVICIOS INTEGRALES TECHPA SPA, RUT 76.552.663-9, de
                conformidad con la Res. Ex. N°11, de 2003, del SII para que le envíe vía email
                registrado, los documentos correspondientes al suministro del servicio. En este
                acto, el Cliente se compromete a imprimir los documentos recibidos, en forma
                inmediata, en la calidad que asegure permanencia y en el tamaño y forma generada. El
                Cliente solicita el envío de sus documentos tributarios al correo electrónico
                indicado en el presente Contrato. En el caso de que el Cliente requiera modificar el
                email de facturación electrónica, deberá realizar dicha solicitud por escrito a
                finanzas@techpa.cl.
            </p>

            <p>
                <strong>III. SERVICIO DE PLATAFORMA, DESARROLLOS Y DATOS</strong>
            </p>

            <p>
                <strong>
                    1.Techpa pondrá a disposición del Cliente el Servicio de Plataforma en las
                    siguientes condiciones:
                </strong>
            </p>

            <p>
                a.Periodo de Configuración: La(s) Plataforma(s) Techpa, serán configuradas a la
                medida de cada Cliente, con las limitantes propias de un desarrollo transversal y no
                especifico, y cada uno de los equipos utilizados. Es por ello que, por cada
                incorporación de equipos y desarrollo solicitado, se realizarán análisis y estudios
                por un periodo de 15 días corridos desde la fecha de instalación, sin poder ser esto
                adjudicado a un mal funcionamiento por parte del cliente hacia Techpa, con el fin de
                garantizar el correcto y óptimo funcionamiento de lo solicitado por el Cliente.
            </p>

            <p>
                b.Actualizaciones: La(s) Plataforma(s) Techpa está(n) en constante actualización,
                incorporando y modificando contenidos. Dichas actualizaciones serán constantemente
                informadas al Cliente, poniendo a su disposición manuales, video tutoriales y un
                equipo de soporte a disposición, con el fin de poder sacar el mayor rendimiento
                posible de la(s) Plataforma(s) por parte del Cliente.
            </p>

            <p>
                c.Características Generales: Techpa no pondrá restricción en aspectos como cantidad
                de usuarios, informes, alertas, notificaciones, etc al Cliente, así como tampoco en
                lo que respecta al tráfico de datos puestos a disposición de los equipos AVL. Sin
                embargo, con respecto al tráfico de datos, estos no podrán ser utilizados en
                dispositivos distintos para los cuales fueron contratados. El solo hecho de
                insertarlos en otro dispositivo generará el termino inmediato del o los contratos
                suscritos con Techpa, siendo de cargo del Cliente el pago íntegro de todo lo
                adeudado, lo pactado y las multas correspondientes.
            </p>

            <p>
                d.Todo desarrollo especifico para el Cliente, quedará sujeto a un anexo de contrato
                que podrá generar costos adicionales y/o duración mínima del contrato, el cual
                modificará las clausulas respectivas del presente contrato.
            </p>

            <p>
                <strong>IV. EQUIPOS</strong>
            </p>

            <p>
                <strong>
                    1. Los equipos a utilizar en la(s) Plataforma(s) Techpa podrán ser incorporados
                    por las siguientes vías:
                </strong>
            </p>

            <p>
                a.Equipos vendidos por Techpa: Esta modalidad permite al Cliente, a través de un
                único pago, ser propietario de su equipo AVL, el cual podrá utilizar en nuestra(s)
                Plataforma(s), cancelando solo el Servicio de Plataforma. La Garantía de los equipos
                vendidos por nuestra empresa es de 6 meses.
            </p>

            <p>
                b.Equipos en Arriendo: Esta modalidad permite al Cliente, a través de un pago
                mensual de Arriendo, tener a su disposición un equipo AVL, el cual podrá utilizar en
                nuestra(s) Plataforma(s), cancelando el Servicio de Plataforma. La Garantía de los
                equipos en Arriendo es Ilimitada mientras se mantenga el Contrato de Servicio de
                Plataforma vigente. *Excluye intervenciones físicas o virtuales de terceros, sin
                autorización de Techpa.
            </p>

            <p>
                c.Equipos Migrados: Esta modalidad permite al Cliente utilizar sus equipos AVL en
                nuestra(s) Plataforma(s). Estos equipos no cuentan con Garantía por parte de Techpa.
                La migración de equipos queda sujeta a factibilidad técnica por parte de Techpa,
                considerando aspectos relevantes para la seguridad de nuestros Clientes, como lo es
                la estabilidad, precisión y calidad de los equipos que se desean migrar.
            </p>

            <p>
                <strong>2.Condiciones de Uso:</strong>
                El Cliente no podrá intervenir en la programación del(os) equipos (s) puestos a su
                disposición. Asimismo, el Cliente no podrá subarrendar el/los equipo(s). El
                incumplimiento de cualquiera de estas condiciones establecidas en el presente
                contrato dará derecho a TECHPA a poner término ipso facto al mismo
            </p>

            <p>
                <strong>3.REPOSICIÓN DE LOS EQUIPOS:</strong>
                En caso de fallas del fabricante, TECHPA procederá a la reposición de equipos de
                conformidad a la garantía otorgada por el fabricante o TECHPA, en función de la
                modalidad contratada (Venta o Arriendo)
            </p>

            <p>
                <strong> 4.Robo, Hurto, Perdida o Daño del/los Equipos(s):</strong>
                El Cliente será el único responsable de la tenencia y uso de los equipos materia del
                presente contrato, debiendo utilizarlos con la máxima diligencia. Las reparaciones
                son de cargo de TECHPA durante el período de garantía, siempre y cuando no exista
                intervención de tercero. Si se comprueba intervención o mal uso, será de cargo del
                arrendatario. En caso de pérdida, robo, extravío, deterioro de los equipos
                entregados por TECHPA, el Cliente estará obligado a pagar a ésta última el valor
                íntegro del equipo.
            </p>

            <p>
                En caso de robo, hurto, pérdida o daño, por cualquier causa, del equipo en Arriendo,
                el Cliente continuará responsable del uso y pago del servicio. El cliente deberá dar
                aviso a TECHPA a través de los procedimientos vigentes para el bloqueo del equipo
                terminal siniestrado. El Cliente podrá convenir una forma de pago del equipo
                siniestrado y la reposición de uno nuevo, con los costos respectivos en función del
                siguiente punto.
            </p>

            <p>
                <strong>5.Valor de Equipos AVL y Accesorios:</strong>
                Para todos los efectos de cálculo se utilizará la siguiente tabla.
            </p>

            <div className="row">
                <div className="col-4">
                    <table className="table contract-table">
                        <thead>
                            <tr>
                                <th>EQUIPO</th>
                                <th>VALOR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listDevices.map((dev, i) => (
                                <tr key={i}>
                                    <td>{dev.name}</td>
                                    <td>{dev.value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <p>Valores expresados en UF + IVA.</p>

            <p>
                <strong>V. INSTALACIONES – SOPORTE EN TERRENO</strong>
            </p>

            <p>
                1.Condiciones: Las instalaciones y soporte en terreno, se realizarán en lugar y
                horario definido en conjunto con el cliente. La duración será entre 30 y 180 minutos
                por cada unidad, de acuerdo con el grado de complejidad del vehículo, equipo AVL y
                accesorios.
            </p>

            <p>
                2.Cambios de Fecha, Hora y Lugar: El Cliente podrá realizar cambios de fecha, hora y
                lugar con al menos 12 horas hábiles, con el fin de permitir a Techpa replanificar su
                visita. Cualquier cambio con menos de 12 horas hábiles, se considerará un “servicio
                fallido”.
            </p>

            <p>
                3.Servicio Fallido: Se considera un servicio fallido toda espera por más de 30
                minutos por el equipo de Techpa, sin que se presenten las condiciones para trabajar
                como, por ejemplo; que no llegue el vehículo al lugar y hora acordada, que no estén
                disponibles las llaves del vehículo para poder trabajar, que no se encuentren
                moradores en el lugar y hora señalados, etc. Lo anterior permitirá a Techpa efectuar
                el cobro de 1 UF por vehículo a Instalar/Dar soporte y reagendar nuevamente con los
                costos asociados a una nueva instalación.
            </p>

            <p>4.Todo Soporte en terreno sujeto a garantía no tendrá costo para el cliente.</p>

            <p>
                5.Todo Soporte en Terreno en el cual se evidencia una vulneración al equipo AVL y/o
                sus accesorios, tendrá un costo de 1 UF + IVA por unidad.
            </p>

            <p>
                6.Todo Soporte en Terreno que implique un cambio de equipo AVL y/o accesorios de una
                unidad a otra, tendrá un costo de 1 UF + IVA por unidad.
            </p>

            <p>
                7. El Cliente deberá generar las condiciones optimas de trabajo, sin exponer la
                seguridad del personal de TECHPA.
            </p>

            <p>
                <strong>VI.RED DE DATOS</strong>
            </p>

            <p>
                1.Los equipos GPS están diseñados para trabajar en ciertas redes de datos (2G – 3G –
                4G, etc) o una combinación de las mismas, redes que podrían presentar fallas u
                obsolescencia, sin ser esta responsabilidad de TECHPA, por depender de las compañías
                de telecomunicaciones proveedoras del servicio.
            </p>

            <p>
                2.En caso de que existe una falla momentánea en la red en la cual opera el equipo
                GPS asignado al cliente, TECHPA, gestionará todas las acciones que estén dentro sus
                atribuciones para lograr la conectividad en el menor tiempo posible, esto basado en
                el plan de datos elegido por el cliente, así como también, con las acciones directas
                a realizar con las compañías proveedoras del servicio de datos, sin poder ser esto
                imputado a TECHPA, como una baja o mala calidad de servicio.
            </p>

            <p>
                3.En caso de obsolescencia de la red en la cual opera el equipo GPS asignado al
                cliente, TECHPA, gestionará el cambio de equipo GPS por uno de iguales o mejores
                prestaciones y compatible con la nueva Red de Datos disponible. Dicho cambio no
                tendrá costo para el cliente, sin embargo, puede generar un alza en el valor mensual
                del servicio, como resultado del mayor valor del nuevo equipo GPS.
            </p>

            <p>
                <strong>VII. ARRIENDO</strong>
            </p>

            <p>
                1.1.TECHPA, entrega al Cliente individualizado al inicio del presente contrato,
                el(los) equipo(s) AVL y accesorios de fábrica que se sean necesarios, acordes al
                servicio contratado y las necesidades del cliente, nuevos y sin uso, en perfectas
                condiciones de funcionamiento, sin que presenten daño alguno. El(los) Equipo(s) son
                de propiedad de TECHPA y se entregan en arriendo por el plazo de vigencia
                establecido en este instrumento.
            </p>

            <p>
                1.2.El Cliente declara que el(los) equipo(s) y accesorios objeto del presente
                Contrato son recibidos a su entera satisfacción, en perfectas condiciones de
                funcionamiento y estado.
            </p>

            <p>
                <strong>2.VIGENCIA:</strong>
                El presente contrato tendrá una vigencia detallada en el punto primero del presente
                contrato en el apartado “Detalle”, renovándose de manera automática y tacita por
                períodos iguales y sucesivos. En caso de que el Cliente manifieste su intención de
                no continuar con el arriendo al término de su vigencia o de cualquiera de sus
                prórrogas, deberá dar aviso mediante carta certificada despachada al domicilio de
                TECHPA con una anticipación de 60 días a la fecha de vencimiento. Al término de
                vigencia, en el caso que el Cliente ejerza la opción de no renovar el Arriendo de
                Equipos y/o Accesorios, deberá devolver el/los Equipos y/o Accesorios a TECHPA, en
                un plazo máximo de 5 días contados desde el término del contrato o cualquiera de sus
                prórrogas.
            </p>

            <p>
                <strong>3.DEL PRECIO:</strong>
                El precio del arrendamiento mensual será el detallado en el punto primero del
                presente contrato en “Detalle”
            </p>

            <p>
                <strong>4.DEL PAGO DEL ARRIENDO:</strong>
            </p>

            <p>
                4.1.El valor de cada renta mensual se facturará mensualmente, mes en curso, y será
                parte de la renta total mensual facturada.
            </p>

            <p>
                4.2.El Cliente deberá pagar la totalidad de las rentas mensuales comprometidas en el
                presente contrato.
            </p>

            <p>
                4.3. El Cliente faculta expresamente a TECHPA para cobrar la(s) renta(s) del
                arrendamiento en la misma boleta o factura de servicio de plataforma.
            </p>

            <p>
                4.4.El Cliente se obliga a pagar la(s) rentas(s) del arrendamiento en la fecha de
                pago indicada en la boleta o factura de servicio.
            </p>

            <p>
                4.5.La mora o simple retardo en el pago de una o cualquiera de las rentas mensuales
                indicadas en el presente instrumento facultará a TECHPA a exigir, de manera
                anticipada, el pago del resto de las rentas que se encuentren pendientes de pago
                hasta el término del arriendo y pago de la totalidad de las cuotas pactadas.
                Asimismo, TECHPA quedará facultada para aplicar al monto adeudado, el interés
                corriente permitido por la ley, desde la fecha en que se hizo exigible la obligación
                y hasta el pago efectivo, sin perjuicio que el Cliente deberá pagar los gastos de
                cobranza detallados en el artículo 37° de la Ley N° 19.496.
            </p>

            <p>
                4.6. El no pago de una o más de las rentas mensuales convenidas en este instrumento,
                facultará a TECHPA para bloquear temporalmente el IMEI del equipo, en tanto no se
                regularicen las cuotas adeudadas, de manera que el equipo quedará inhabilitado para
                operar en la red pública telefónica.
            </p>

            <p>
                4.7. Los pagos recibidos se imputarán a la deuda más antigua que el ARRENDATARIO
                mantenga con TECHPA, por cualquier causa o concepto.
            </p>
        </div>
    );
};

export default ContractPreview;
