import React, { useState } from 'react';

const useForm = (initialState = {}) => {
    const [state, setState] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const handleCheck = (e) => {
        const { name, checked } = e.target;
        setState({ ...state, [name]: checked });
    };

    const handleChangeByName = (name, value) => {
        setState({ ...state, [name]: value });
    };

    const handleMultiChange = (value) => {
        setState({ ...state, ...value });
    };

    const handleFile = (e) => {
        const { name, files } = e.target;
        setState({ ...state, [name]: files[0] });
    };

    const handleCheckIdList = (e) => {
        const { name, value } = e.target;
        let list = [...state[name]];

        const index = list.indexOf(parseInt(value));
        if (e.target.checked) {
            list.push(parseInt(value)); // push in array checked value
        } else {
            list.splice(index, 1); // removed in array unchecked value
        }

        setState({ ...state, [name]: list });
    };

    return {
        state,
        handleChange,
        handleChangeByName,
        handleMultiChange,
        handleCheck,
        handleFile,
        handleCheckIdList,
    };
};

export default useForm;
