export const clientTypeList = ['Empresa', 'Persona'];

export const clientType = {
    Empresa: 'Empresa',
    Persona: 'Persona',
};

export const defaultDetObs = `Valores en UF+IVA. Considera instalación en Talca por 2 equipos (4UF) y 
Costo mensual por 2 equipos (1 UF). Pago de primera factura a 5 días. Facturación los días 1, mes en curso, 
vencimiento los días 20. Toda nueva incorporación de equipos, servicios se regirá por el valor unitario antes mencionado, 
sin perjuicio de los descuentos que pueda realizar Techpa al cliente. Así mismo, toda nueva incorporación de equipos no 
requerirá nuevo contrato, a menos que cambien las condiciones comerciales, siendo válida la solicitud, por cualquier 
canal disponible con el cliente.`;

export const contractStatus = {
    Pendiente: 1,
    Firmado: 2,
    Aprobado: 3,
};

export const contractTypeVal = {
    SinPermanencia: 1,
    ConPermanencia: 2,
};

export const contractTypeList = [
    {
        name: 'Sin permanencia',
        value: 1,
    },
    {
        name: 'Con permanencia',
        value: 2,
    },
];

export const userRole = {
    admin: 1,
    coach: 2,
};

export const Countries = [
    {
        name: 'Argentina',
        cities: [
            {
                name: 'Bahía Blanca',
            },
            {
                name: 'Banfield nota 2​',
            },
            {
                name: 'Berazategui',
            },
            {
                name: 'Buenos Aires',
            },
            {
                name: 'Comodoro Rivadavia',
            },
            {
                name: 'Córdoba',
            },
            {
                name: 'Corrientes',
            },
            {
                name: 'Formosa',
            },
            {
                name: 'Godoy Cruz',
            },
            {
                name: 'González Catán',
            },
            {
                name: 'Gregorio de Laferrere',
            },
            {
                name: 'Guaymallén',
            },
            {
                name: 'Isidro Casanova',
            },
            {
                name: 'José C. Paz',
            },
            {
                name: 'La Plata',
            },
            {
                name: 'La Rioja',
            },
            {
                name: 'Lanús',
            },
            {
                name: 'Las Heras',
            },
            {
                name: 'Mar del Plata',
            },
            {
                name: 'Merlo nota 1​',
            },
            {
                name: 'Moreno',
            },
            {
                name: 'Neuquén',
            },
            {
                name: 'Paraná',
            },
            {
                name: 'Posadas',
            },
            {
                name: 'Quilmes',
            },
            {
                name: 'Resistencia',
            },
            {
                name: 'Río Cuarto',
            },
            {
                name: 'Rosario',
            },
            {
                name: 'Salta',
            },
            {
                name: 'San Fernando del Valle de Catamarca',
            },
            {
                name: 'San Luis',
            },
            {
                name: 'San Miguel de Tucumán',
            },
            {
                name: 'San Miguel nota 3​',
            },
            {
                name: 'San Salvador de Jujuy',
            },
            {
                name: 'Santa Fe',
            },
            {
                name: 'Santiago del Estero',
            },
            {
                name: 'Virrey del Pino',
            },
        ],
    },
    {
        name: 'Colombia',
        cities: [
            {
                name: 'MADRID',
            },
            {
                name: 'BOJACA',
            },
            {
                name: 'ZIPACON',
            },
            {
                name: 'EL ROSAL',
            },
            {
                name: 'SUBACHOQUE',
            },
            {
                name: 'CAJICA',
            },
            {
                name: 'NEMOCON',
            },
            {
                name: 'COGUA',
            },
            {
                name: 'UBATE',
            },
            {
                name: 'COTA',
            },
            {
                name: 'TABIO',
            },
            {
                name: 'TENJO',
            },
            {
                name: 'SOPO',
            },
            {
                name: 'TOCANCIPA',
            },
            {
                name: 'GACHANCIPA',
            },
            {
                name: 'CHOCONTA',
            },
            {
                name: 'GACHETA',
            },
            {
                name: 'VILLETA',
            },
            {
                name: 'GUADUAS',
            },
            {
                name: 'LA MESA',
            },
            {
                name: 'CAQUEZA',
            },
            {
                name: 'MEDINA',
            },
            {
                name: 'PACHO',
            },
            {
                name: 'SAN JUAN DE RIO SECO',
            },
        ],
    },
    {
        name: 'México',
        cities: [
            {
                name: 'Acapulco',
            },
            {
                name: 'Aguascalientes',
            },
            {
                name: 'Campeche',
            },
            {
                name: 'Cancún',
            },
            {
                name: 'Cárdenas',
            },
            {
                name: 'Celaya',
            },
            {
                name: 'Chetumal',
            },

            {
                name: 'Chihuahua',
            },
            {
                name: 'Chilpancingo',
            },
            {
                name: 'Ciudad del Carmen',
            },
            {
                name: 'Ciudad Obregón',
            },
            {
                name: 'Ciudad Victoria',
            },
            {
                name: 'Coatzacoalcos',
            },
            {
                name: 'Colima-Villa de Álvarez',
            },
            {
                name: 'Córdoba',
            },
            {
                name: 'Cuautla',
            },
            {
                name: 'Cuernavaca',
            },
            {
                name: 'Culiacán',
            },
            {
                name: 'Durango',
            },
            {
                name: 'Ensenada',
            },
            {
                name: 'Guadalajara',
            },
            {
                name: 'Guanajuato',
            },
            {
                name: 'Guaymas',
            },
            {
                name: 'Hermosillo',
            },
            {
                name: 'Irapuato',
            },
            {
                name: 'Juárez',
            },
            {
                name: 'La Laguna',
            },
            {
                name: 'La Paz',
            },
            {
                name: 'La Piedad-Pénjamo',
            },
            {
                name: 'León',
            },
            {
                name: 'Los Cabos',
            },
            {
                name: 'Los Mochis',
            },
            {
                name: 'Manzanillo',
            },
            {
                name: 'Matamoros',
            },
            {
                name: 'Mazatlán',
            },
            {
                name: 'Mérida',
            },
            {
                name: 'Mexicali',
            },
            {
                name: 'Minatitlán',
            },
            {
                name: 'Monclova-Frontera',
            },
            {
                name: 'Monterrey',
            },
            {
                name: 'Nuevo Laredo',
            },
            {
                name: 'Oaxaca',
            },
            {
                name: 'Ocotlán',
            },
            {
                name: 'Orizaba',
            },
            {
                name: 'Pachuca',
            },
            {
                name: 'Piedras Negras',
            },
            {
                name: 'Poza Rica',
            },
            {
                name: 'Puebla-Tlaxcala',
            },
            {
                name: 'Puerto Vallarta',
            },
            {
                name: 'Querétaro',
            },
            {
                name: 'Reynosa-Río Bravo',
            },
            {
                name: 'Rioverde-Ciudad Fernández',
            },
            {
                name: 'Salamanca',
            },
            {
                name: 'Saltillo',
            },
            {
                name: 'San Francisco del Rincón',
            },
            {
                name: 'San Juan del Río',
            },
            {
                name: 'San Luis Potosí-Soledad',
            },
            {
                name: 'Tampico-Pánuco',
            },
            {
                name: 'Tapachula',
            },
            {
                name: 'Tecomán',
            },
            {
                name: 'Tehuacán',
            },
            {
                name: 'Tehuantepec-Salina Cruz',
            },
            {
                name: 'Tepic',
            },
            {
                name: 'Tijuana',
            },
            {
                name: 'Tlaxcala-Apizaco',
            },
            {
                name: 'Toluca',
            },
            {
                name: 'Tula',
            },
            {
                name: 'Tulancingo',
            },
            {
                name: 'Tuxtla Gutiérrez',
            },
            {
                name: 'Uruapan',
            },
            {
                name: 'Valle de México',
            },
            {
                name: 'Veracruz',
            },
            {
                name: 'Villahermosa',
            },
            {
                name: 'Xalapa',
            },
            {
                name: 'Zacatecas-Guadalupe',
            },
            {
                name: 'Zamora-Jacona',
            },
        ],
    },
    {
        name: 'USA',
        cities: [
            {
                name: 'Abilene',
            },
            {
                name: 'Akron',
            },
            {
                name: 'Albuquerque',
            },
            {
                name: 'Alexandría',
            },
            {
                name: 'Allentown',
            },
            {
                name: 'Amarillo',
            },
            {
                name: 'Anaheim',
            },
            {
                name: 'Anchorage',
            },
            {
                name: 'Ann Arbor',
            },
            {
                name: 'Antioch',
            },
            {
                name: 'Arlington',
            },
            {
                name: 'Arvada',
            },
            {
                name: 'Athens',
            },
            {
                name: 'Atlanta',
            },
            {
                name: 'Augusta',
            },
            {
                name: 'Aurora',
            },
            {
                name: 'Austin',
            },
            {
                name: 'Bakersfield',
            },
            {
                name: 'Baltimore',
            },
            {
                name: 'Baton Rouge',
            },
            {
                name: 'Beaumont',
            },
            {
                name: 'Bellevue',
            },
            {
                name: 'Berkeley',
            },
            {
                name: 'Billings',
            },
            {
                name: 'Birmingham',
            },
            {
                name: 'Boise',
            },
            {
                name: 'Boston',
            },
            {
                name: 'Bridgeport',
            },
            {
                name: 'Brownsville',
            },
            {
                name: 'Búfalo',
            },
            {
                name: 'Burbank',
            },
            {
                name: 'Cabo Coral',
            },
            {
                name: 'Cambridge',
            },
            {
                name: 'Carlsbad',
            },
            {
                name: 'Carrollton',
            },
            {
                name: 'Cary',
            },
            {
                name: 'Cedar Rapids',
            },
            {
                name: 'Centennial',
            },
            {
                name: 'Chandler',
            },
            {
                name: 'Charleston',
            },
            {
                name: 'Charlotte',
            },
            {
                name: 'Chattanooga',
            },
            {
                name: 'Chesapeake',
            },
            {
                name: 'Chicago',
            },
            {
                name: 'Chula Vista',
            },
            {
                name: 'Cincinnati',
            },
            {
                name: 'Clarksville',
            },
            {
                name: 'Clearwater',
            },
            {
                name: 'Cleveland',
            },
            {
                name: 'Colorado Springs',
            },
            {
                name: 'Columbia',
            },
            {
                name: 'Columbus',
            },
            {
                name: 'Concord',
            },
            {
                name: 'Coral Springs',
            },
            {
                name: 'Corona',
            },
            {
                name: 'Corpus Christi',
            },
            {
                name: 'Costa Mesa',
            },
            {
                name: 'Dallas',
            },
            {
                name: 'Daly City',
            },
            {
                name: 'Dayton',
            },
            {
                name: 'Denton',
            },
            {
                name: 'Dénver',
            },
            {
                name: 'Des Moines',
            },
            {
                name: 'Downey',
            },
            {
                name: 'Durham',
            },
            {
                name: 'El Monte',
            },
            {
                name: 'El Paso',
            },
            {
                name: 'Elgin',
            },
            {
                name: 'Elizabeth',
            },
            {
                name: 'Elk Grove',
            },
            {
                name: 'Erie',
            },
            {
                name: 'Escondido',
            },
            {
                name: 'Eugene',
            },
            {
                name: 'Evansville',
            },
            {
                name: 'Everett',
            },
            {
                name: 'Evansville',
            },
            {
                name: 'Fairfield',
            },
            {
                name: 'Fargo',
            },
            {
                name: 'Fayetteville',
            },
            {
                name: 'Evansville',
            },
            {
                name: 'Filadelfia',
            },
            {
                name: 'Evansville',
            },
            {
                name: 'Flint',
            },
            {
                name: 'Fontana',
            },
            {
                name: 'Fort Collins',
            },
            {
                name: 'Fort Lauderdale',
            },
            {
                name: 'Fort Wayne',
            },
            {
                name: 'Fort Worth',
            },
            {
                name: 'Fremont',
            },
            {
                name: 'Evansville',
            },
            {
                name: 'Fresno',
            },
            {
                name: 'Frisco',
            },
            {
                name: 'Fullerton',
            },
            {
                name: 'Gainesville',
            },
            {
                name: 'Garden Grove',
            },
            {
                name: 'Garland',
            },
            {
                name: 'Gilbert',
            },
            {
                name: 'Glendale',
            },
            {
                name: 'Glendale',
            },
            {
                name: 'Grand Prairie',
            },
            {
                name: 'Grand Rapids',
            },
            {
                name: 'Green Bay',
            },
            {
                name: 'Greensboro',
            },
            {
                name: 'Gresham',
            },
            {
                name: 'Hampton',
            },
            {
                name: 'Hartford',
            },
            {
                name: 'Hayward',
            },
            {
                name: 'Henderson',
            },
            {
                name: 'Hialeah',
            },
            {
                name: 'High Point',
            },
            {
                name: 'Hollywood',
            },
            {
                name: 'Honolulú',
            },
            {
                name: 'Houston',
            },
            {
                name: 'Huntington Beach',
            },
            {
                name: 'Huntsville',
            },
            {
                name: 'Independence',
            },
            {
                name: 'Indianápolis',
            },
            {
                name: 'Inglewood',
            },
            {
                name: 'Irvine',
            },
            {
                name: 'Irving',
            },
            {
                name: 'Jackson',
            },
            {
                name: 'Jacksonville',
            },
            {
                name: 'Jersey City',
            },
            {
                name: 'Joliet',
            },
            {
                name: 'Kansas City',
            },
            {
                name: 'Kansas City (Misuri)',
            },
            {
                name: 'Killeen',
            },
            {
                name: 'Knoxville',
            },
            {
                name: 'Lafayette',
            },
            {
                name: 'Lakewood',
            },
            {
                name: 'Lancaster',
            },
            {
                name: 'Lansing',
            },
            {
                name: 'Laredo',
            },
            {
                name: 'Las Vegas',
            },
            {
                name: 'Lexington',
            },
            {
                name: 'Lincoln',
            },
            {
                name: 'Lexington',
            },
            {
                name: 'Little Rock',
            },
            {
                name: 'Long Beach',
            },
            {
                name: 'Los Ángeles',
            },
            {
                name: 'Louisville',
            },
            {
                name: 'Lowell',
            },
            {
                name: 'Lubbock',
            },
            {
                name: 'Madison',
            },
            {
                name: 'Mánchester',
            },
            {
                name: 'McAllen',
            },
            {
                name: 'McKinney',
            },
            {
                name: 'Memphis',
            },
            {
                name: 'Mesa',
            },
            {
                name: 'Mesquite',
            },
            {
                name: 'Metairie',
            },
            {
                name: 'Miami',
            },
            {
                name: 'Miami Gardens',
            },
            {
                name: 'Midland',
            },
            {
                name: 'Milwaukee',
            },
            {
                name: 'Mineápolis',
            },
            {
                name: 'Miramar',
            },
            {
                name: 'Mobile',
            },
            {
                name: 'Modesto',
            },
            {
                name: 'Montgomery',
            },
            {
                name: 'Moreno Valley',
            },
            {
                name: 'Murfreesboro',
            },
            {
                name: 'Murrieta',
            },
            {
                name: 'Naperville',
            },
            {
                name: 'Nashville',
            },
            {
                name: 'New Haven',
            },
            {
                name: 'Newark',
            },
            {
                name: 'Newport News',
            },
            {
                name: 'Norfolk',
            },
            {
                name: 'Norman',
            },
            {
                name: 'North Las Vegas',
            },
            {
                name: 'Norwalk',
            },
            {
                name: 'Nueva Orleans',
            },
            {
                name: 'Oakland',
            },
            {
                name: 'Oceanside',
            },
            {
                name: 'Oklahoma City',
            },
            {
                name: 'Olathe',
            },
            {
                name: 'Omaha',
            },
            {
                name: 'Ontario',
            },
            {
                name: 'Orange',
            },
            {
                name: 'Orlando',
            },
            {
                name: 'Overland Park',
            },
            {
                name: 'Oxnard',
            },
            {
                name: 'Palm Bay',
            },
            {
                name: 'Palmdale',
            },
            {
                name: 'Paradise',
            },
            {
                name: 'Pasadena',
            },
            {
                name: 'Paterson',
            },
            {
                name: 'Pembroke Pines',
            },
            {
                name: 'Peoria',
            },
            {
                name: 'Phoenix',
            },
            {
                name: 'Pittsburgh',
            },
            {
                name: 'Plano',
            },
            {
                name: 'Pomona',
            },
            {
                name: 'Port Saint Lucie',
            },
            {
                name: 'Portland',
            },
            {
                name: 'Providence',
            },
            {
                name: 'Provo',
            },
            {
                name: 'Pueblo',
            },
            {
                name: 'Raleigh',
            },
            {
                name: 'Rancho Cucamonga',
            },
            {
                name: 'Reno',
            },
            {
                name: 'Richmond',
            },
            {
                name: 'Riverside',
            },
            {
                name: 'Rochester',
            },
            {
                name: 'Rockford',
            },
            {
                name: 'Roseville',
            },
            {
                name: 'Saint Paul',
            },
            {
                name: 'Salem',
            },
            {
                name: 'Salinas',
            },
            {
                name: 'Salt Lake City',
            },
            {
                name: 'San Antonio',
            },
            {
                name: 'San Bernardino',
            },
            {
                name: 'San Diego',
            },
            {
                name: 'San Francisco',
            },
            {
                name: 'San José',
            },
            {
                name: 'San Luis',
            },
            {
                name: 'San Petersburgo',
            },
            {
                name: 'Santa Ana',
            },
            {
                name: 'Santa Clara',
            },
            {
                name: 'Santa Clarita',
            },
            {
                name: 'Santa Rosa',
            },
            {
                name: 'Savannah',
            },
            {
                name: 'Scottsdale',
            },
            {
                name: 'Seattle',
            },
            {
                name: 'Shreveport',
            },
            {
                name: 'Simi Valley',
            },
            {
                name: 'Sioux Falls',
            },
            {
                name: 'Siracusa',
            },
            {
                name: 'South Bend',
            },
            {
                name: 'Spokane',
            },
            {
                name: 'Springfield',
            },
            {
                name: 'Stamford',
            },
            {
                name: 'Sterling Heights',
            },
            {
                name: 'Stockton',
            },
            {
                name: 'Sunnyvale',
            },
            {
                name: 'Surprise',
            },
            {
                name: 'Tacoma',
            },
            {
                name: 'Tallahassee',
            },
            {
                name: 'Tampa',
            },
            {
                name: 'Temecula',
            },
            {
                name: 'Tempe',
            },
            {
                name: 'Thornton',
            },
            {
                name: 'Thousand Oaks',
            },
            {
                name: 'Toledo',
            },
            {
                name: 'Topeka',
            },
            {
                name: 'Torrance',
            },
            {
                name: 'Tucson',
            },
            {
                name: 'Tulsa',
            },
            {
                name: 'Vallejo',
            },
            {
                name: 'Vancouver',
            },
            {
                name: 'Ventura',
            },
            {
                name: 'Victorville',
            },
            {
                name: 'Virginia Beach',
            },
            {
                name: 'Visalia',
            },
            {
                name: 'Waco',
            },
            {
                name: 'Washington D. C.',
            },
            {
                name: 'Waterbury',
            },
            {
                name: 'West Covina',
            },
            {
                name: 'West Jordan',
            },
            {
                name: 'West Valley City',
            },
            {
                name: 'Westminster',
            },
            {
                name: 'Wichita',
            },
            {
                name: 'Wichita Falls',
            },
            {
                name: 'Wilmington',
            },
            {
                name: 'Winston-Salem',
            },
            {
                name: 'Worcester',
            },
            {
                name: 'Yonkers',
            },
        ],
    },
    {
        name: 'Panamá',
        cities: [
            {
                name: 'Aguadulce',
            },
            {
                name: 'Almirante',
            },
            {
                name: 'Antón',
            },
            {
                name: 'Bugaba',
            },
            {
                name: 'Capira',
            },
            {
                name: 'Chame',
            },
            {
                name: 'Changuinola',
            },
            {
                name: 'Chepo',
            },
            {
                name: 'Chitré',
            },
            {
                name: 'Colón',
            },
            {
                name: 'David',
            },
            {
                name: 'La Chorrera',
            },
            {
                name: 'La Pintada',
            },
            {
                name: 'La Villa de Los Santos',
            },
            {
                name: 'Las Tablas',
            },
            {
                name: 'Panamá',
            },
            {
                name: 'Penonomé',
            },
            {
                name: 'Puerto Armuelles',
            },
            {
                name: 'Santiago',
            },
            {
                name: 'Soná',
            },
        ],
    },
    {
        name: 'Peru',
        cities: [
            {
                name: 'Abancay',
            },
            {
                name: 'Andahuaylas',
            },
            {
                name: 'Arequipa',
            },
            {
                name: 'Ayacucho',
            },
            {
                name: 'Barranca',
            },
            {
                name: 'Cajamarca',
            },
            {
                name: 'Callao',
            },
            {
                name: 'Cañete',
            },
            {
                name: 'Catacaos',
            },
            {
                name: 'Cerro de Pasco',
            },
            {
                name: 'Chancay',
            },
            {
                name: 'Chiclayo',
            },
            {
                name: 'Chimbote',
            },
            {
                name: 'Chincha Alta',
            },
            {
                name: 'Chulucanas',
            },
            {
                name: 'Cuzco',
            },
            {
                name: 'Huacho',
            },
            {
                name: 'Huancavelica',
            },
            {
                name: 'Huancayo',
            },
            {
                name: 'Huanchaco',
            },
            {
                name: 'Huánuco',
            },
            {
                name: 'Huaral',
            },
            {
                name: 'Huaraz',
            },
            {
                name: 'Huaura',
            },
            {
                name: 'Ica',
            },
            {
                name: 'Ilo',
            },
            {
                name: 'Iquitos',
            },
            {
                name: 'Jaén',
            },
            {
                name: 'Juliaca',
            },
            {
                name: 'Lambayeque',
            },
            {
                name: 'Lima',
            },
            {
                name: 'Majes',
            },
            {
                name: 'Moquegua',
            },
            {
                name: 'Moyobamba',
            },
            {
                name: 'Paita',
            },
            {
                name: 'Pisco',
            },
            {
                name: 'Piura',
            },
            {
                name: 'Pucallpa',
            },
            {
                name: 'Puerto Maldonado',
            },
            {
                name: 'Puno',
            },
            {
                name: 'San Miguel',
            },
            {
                name: 'Sicuani',
            },
            {
                name: 'Sullana',
            },
            {
                name: 'Tacna',
            },
            {
                name: 'Talara',
            },
            {
                name: 'Tarapoto',
            },
            {
                name: 'Tingo María',
            },
            {
                name: 'Trujillo',
            },
            {
                name: 'Tumbes',
            },
            {
                name: 'Yurimaguas',
            },
        ],
    },
    {
        name: 'Chile',
        cities: [
            {
                name: 'Angol',
            },
            {
                name: 'Antofagasta',
            },
            {
                name: 'Arica',
            },
            {
                name: 'Buin',
            },
            {
                name: 'Calama',
            },
            {
                name: 'Colina',
            },
            {
                name: 'Constitución',
            },
            {
                name: 'Copiapó',
            },
            {
                name: 'Coronel-Lota',
            },
            {
                name: 'Coyhaique',
            },
            {
                name: 'Curicó',
            },
            {
                name: 'Gran Chillan',
            },
            {
                name: 'Gran Concepción',
            },
            {
                name: 'Gran Iquique',
            },
            {
                name: 'Gran La Serena',
            },
            {
                name: 'Gran Puerto Montt',
            },
            {
                name: 'Gran Quillota',
            },
            {
                name: 'Gran Rancagua',
            },
            {
                name: 'Gran San Antonio',
            },
            {
                name: 'Gran Santiago',
            },
            {
                name: 'Gran Talca',
            },
            {
                name: 'Gran Temuco',
            },
            {
                name: 'Gran Valparaíso',
            },
            {
                name: 'Lampa',
            },
            {
                name: 'Limache-Olmué',
            },
            {
                name: 'Linares',
            },
            {
                name: 'Los Andes-Calle Larga-San Esteban',
            },
            {
                name: 'Los Ángeles',
            },
            {
                name: 'Melipilla',
            },
            {
                name: 'Osorno',
            },
            {
                name: 'Ovalle',
            },
            {
                name: 'Paine',
            },
            {
                name: 'Peñaflor',
            },
            {
                name: 'Punta Arenas',
            },
            {
                name: 'Rengo',
            },
            {
                name: 'San Carlos',
            },
            {
                name: 'San Felipe',
            },
            {
                name: 'San Fernando',
            },
            {
                name: 'San Vicente de Tagua Tagua',
            },
            {
                name: 'Talagante',
            },
            {
                name: 'Valdivia',
            },
            {
                name: 'Vallenar',
            },
            {
                name: 'Villarrica',
            },
        ],
    },
];

export const sessionType = {
    Taller: 'Líder y Equipo',
    CoachingEjecutivo: 'Coaching ejecutivo',
    CoachingIndividual: 'Coaching individual',
    CoachingDeEquipo: 'Coaching de equipo',
    MentoringEjecutivo: 'Mentoring ejecutivo',
    MentoringDeEquipo: 'Mentoring de equipo',
};

export const sessionTypes = [
    'Líder y Equipo',
    'Coaching ejecutivo',
    'Coaching individual',
    'Coaching de equipo',
    'Mentoring ejecutivo',
    'Mentoring de equipo',
];

export const sessionStatus = {
    pending: 0,
    completed: 1,
};

export const questionValues = [1, 2, 3, 4, 5];

export const coachQuestions = [
    {
        name: 'coach_cono',
        text: '1.Conocimiento y dominio del tema',
    },
    {
        name: 'coach_habi_com',
        text: '2.Habilidad para comunicarse y transmitir ideas',
    },
    {
        name: 'coach_habi_pre',
        text: '3.Habilidad para responder preguntas',
    },
    {
        name: 'coach_iden',
        text:
            '4.Identifica las expectativas de los participantes y acuerda los objetivos del taller',
    },
    {
        name: 'coach_herra',
        text: '5.Herramientas utilizadas',
    },
    {
        name: 'coach_pun',
        text: '5.Puntualidad',
    },
    {
        name: 'coach_pre',
        text: '7.Presentación personal',
    },
    {
        name: 'coach_res',
        text: '8.Respeta las ideas y aportes de los participantes',
    },
];

export const sessionQuestions = [
    {
        name: 'ses_cum',
        text: '1. Cumplimiento del programa propuesto',
    },
    {
        name: 'ses_tema',
        text: '2. Qué tan útiles fueron las temáticas abordadas?',
    },
    {
        name: 'ses_log',
        text: '3. Logro de los objetivos propuestos',
    },
    {
        name: 'ses_rela',
        text:
            '4.Relevancia de los conocimientos adquiridos en el curso para su actividad profesional',
    },
];

export const logisticQuestions = [
    {
        name: 'logi_org',
        text: '1. La organización de los talleres',
    },
    {
        name: 'logi_hor',
        text: '2. Horario de la sesión',
    },
    {
        name: 'logi_sal',
        text: '3. Salón donde se realizó el curso',
    },
    {
        name: 'logi_ate',
        text: '4. Atención general recibida',
    },
    {
        name: 'logi_ent',
        text: '5. Entrega oportuna del material necesario',
    },
];

export const customTableStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            fontSize: '18px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            fontSize: '17px',
        },
    },
};

export const centerCell = {
    textAlign: 'center',
};

export const darkCell = {
    backgroundColor: '#f5f5f5',
};

export const darkCellCenter = {
    backgroundColor: '#f5f5f5',
    textAlign: 'center',
};

export const cumplimetOptionList = ['Proyecto', 'Coach', 'Cliente'];

export const cumplimentOptions = {
    Proyecto: 'Proyecto',
    Coach: 'Coach',
    Cliente: 'Cliente',
};

export const areaOptionList = ['Todas las áreas', 'Detalle por área'];

export const areaOptions = {
    Todas: 'Todas las áreas',
    Detalle: 'Detalle por área',
};

export const typeOptionList = ['Todos los tipos', 'Detalle por tipo'];

export const typeOptions = {
    Todas: 'Todos los tipos',
    Detalle: 'Detalle por tipo',
};
