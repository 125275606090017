import React, { useEffect, useState } from 'react';
import { cumplimentOptions, cumplimetOptionList } from '../../constants/data';
import { getData, handleError } from '../../functions/services';
import ChartCumplimentBar from '../charts/CharCumplimentBar';
import ChartCumplimentPie from '../charts/ChartCumplimentPie';
import ChartProyectCumpliment from '../charts/ChartProyectCumpliment';
import Loader from '../common/Loader';
import { formatDateToLocal } from '../../functions/dates';
import ReactSpeedometer from 'react-d3-speedometer';
import ChartBar from '../charts/ChartBar';
import { dualChartColors } from '../../constants/charts';

const ModalCumplimentReports = () => {
    const [cumplimentOption, setCumplimentOption] = useState('');
    const [selection, setSelection] = useState('');
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState('');
    const [loadingCharts, setLoadingCharts] = useState(true);
    const [data, setData] = useState('');

    const loadData = async () => {
        try {
            const req = await getData('reports/cumpliment-options', {});
            setOptions(req.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const loadProyectData = async () => {
        setLoadingCharts(true);
        try {
            const req = await getData(`reports/proyect-cumpliment/${selection}`, {});
            setData(req.data);
            setLoadingCharts(false);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const loadCoachData = async () => {
        setLoadingCharts(true);
        try {
            const req = await getData(`reports/coach-cumpliment/${selection}`, {});
            setData(req.data);
            setLoadingCharts(false);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const loadClientData = async () => {
        setLoadingCharts(true);
        try {
            const req = await getData(`reports/client-cumpliment/${selection}`, {});
            setData(req.data);
            setLoadingCharts(false);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (cumplimentOption === cumplimentOptions.Proyecto && selection) {
            loadProyectData();
        }

        if (cumplimentOption === cumplimentOptions.Coach && selection) {
            loadCoachData();
        }

        if (cumplimentOption === cumplimentOptions.Cliente && selection) {
            loadClientData();
        }
    }, [selection, cumplimentOption]);

    const renderCumplimentOptions = () => {
        const radiOptions = cumplimetOptionList.map((val, i) => {
            return (
                <div className="form-check form-check-inline" key={i}>
                    <input
                        className="form-check-input"
                        type="radio"
                        name="cumplimentOption"
                        value={val}
                        checked={val === cumplimentOption}
                        onChange={(e) => {
                            setSelection('');
                            setCumplimentOption(e.target.value);
                        }}
                    />
                    <label className="form-check-label">{val}</label>
                </div>
            );
        });

        return (
            <div>
                <div>{radiOptions}</div>
                <div className="mt-2">{renderSelect()}</div>
            </div>
        );
    };

    const renderSelect = () => {
        let list;

        switch (cumplimentOption) {
            case cumplimentOptions.Proyecto:
                list = options.proyects.map((val) => {
                    return (
                        <option value={val.id} key={val.id}>
                            {`${val.company.name}-${val.city}-${formatDateToLocal(val.createdAt)}`}
                        </option>
                    );
                });

                break;

            case cumplimentOptions.Coach:
                list = options.coachs.map((val) => {
                    return <option value={val.id} key={val.id}>{`${val.name}`}</option>;
                });

                break;
            case cumplimentOptions.Cliente:
                list = options.clients.map((val) => {
                    return <option value={val.id} key={val.id}>{`${val.name}`}</option>;
                });

                break;

            default:
                break;
        }

        return (
            <select
                name="selection"
                className="form-control"
                value={selection}
                onChange={(e) => {
                    setSelection(e.target.value);
                }}
            >
                <option value="">Escoge una opción</option>
                {list}
            </select>
        );
    };

    const renderContent = () => {
        if (!cumplimentOption || !selection) {
            return;
        }

        if (loadingCharts) {
            return (
                <div className="center-admin-loader-modal">
                    <Loader />
                </div>
            );
        }

        let pieData;

        if (data.total === 0) {
            pieData = [0, 0];
        } else {
            pieData = [(100 - data.percent).toFixed(2), data.percent.toFixed(2)];
        }

        return (
            <div>
                <div>
                    Sesiones totales:
                    <span className="float-right">{data.total}</span>
                </div>
                <hr className="m-0" />

                <div>
                    Sesiones pedientes:
                    <span className="float-right">{data.pending}</span>
                </div>
                <hr className="m-0" />

                <div>
                    Sesiones completadas:
                    <span className="float-right">{data.completed}</span>
                </div>

                <div className="row mt-4">
                    {cumplimentOption === cumplimentOptions.Proyecto ? (
                        <>
                            <div className="col-sm-6 full-center-flex">
                                <ReactSpeedometer
                                    value={data.percent}
                                    maxValue={100}
                                    height={180}
                                    segments={4}
                                    currentValueText={`Cumplimiento al ${data.percent}%`}
                                    forceRender
                                />
                            </div>
                            <div className="col-sm-6">
                                <ChartBar
                                    data={[data.completed, data.pending]}
                                    labels={['Sesiones completadas', 'Sesiones pendientes']}
                                    chartColors={dualChartColors}
                                    label="Cantidad de sesiones"
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="col-sm-6 ">
                                <ChartCumplimentBar
                                    data={[
                                        data.block_0_25,
                                        data.block_26_50,
                                        data.block_51_75,
                                        data.block_76_100,
                                    ]}
                                />
                            </div>

                            <div className="col-sm-6">
                                <ChartCumplimentPie data={pieData} />
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="p-2 modal-title">Reportes de cumplimiento</div>

            {loading ? (
                <div className="center-admin-loader-modal">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="mt-4">{renderCumplimentOptions()}</div>
                    {cumplimentOption && <div className="mt-4">{renderContent()}</div>}
                </>
            )}
        </div>
    );
};

export default ModalCumplimentReports;
