import React, { useEffect, useState } from 'react';
import ChartCard from '../components/ChartCard';
import ChartMostUsedAreas from '../components/charts/ChartMostUsedAreas';
import ChartProyectCumpliment from '../components/charts/ChartProyectCumpliment';
import ChartSessionDistribution from '../components/charts/ChartSessionDistribution';
import * as FaIcons from 'react-icons/fa';
import NumberCard from '../components/NumberCard';
import ProyectsRanking from '../components/ProyectsRanking';
import ClientsRanking from '../components/ClientsRanking';
import CoachsRanking from '../components/CoachsRaking';
import { getData, handleError } from '../functions/services';
import Loader from '../components/common/Loader';
import Modal from 'react-responsive-modal';
import ModalCumplimentReports from '../components/modal/ModalCumplimentReports';
import ModalTypeReports from '../components/modal/ModalTypeReports';
import ModalAreaReports from '../components/modal/ModalAreaReports';
import ModalDelayedProyects from '../components/modal/ModalDelayedProyects';
import LargeNumberCard from '../components/LargeNumberCard';

const DashboardPage = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState('');

    const [openModalCumpliment, setopenModalCumpliment] = useState(false);
    const [openModalArea, setopenModalArea] = useState(false);
    const [openModalType, setopenModalType] = useState(false);
    const [openModalDelayed, setOpenModalDelayed] = useState(false);

    const loadData = async () => {
        try {
            const req = await getData('dashboard/number-cards', {});
            setData(req.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const renderContent = () => {
        return (
            <>
                <br/>
                <div className="row">
                <div className="col-md-9">
                    <div className="row">
                        <LargeNumberCard
                                title="Proyectos"
                                number={data.completedProyects}
                                text="Completados"
                                completed={data.completedProyects}
                                pending={data.pendingProyects}
                                late={data.lateProyects}
                                button= {data.lateProyects > 0 && (
                                            <a
                                                href="#"
                                                className="center-fit"
                                                onClick={() => {
                                                    setOpenModalDelayed(true);
                                                }}
                                            >
                                                Ver demoras
                                            </a>
                                        )}
                            />
                            <LargeNumberCard
                                title="Sesiones"
                                number={data.completedProyects}
                                text="Completados"
                                completed={data.completedSessions}
                                pending={data.pendingSessions}
                                late={data.lateSessions}
                                button= {data.lateProyects > 0 && (
                                            <a
                                                href="#"
                                                className="center-fit"
                                                onClick={() => {
                                                    setOpenModalDelayed(true);
                                                }}
                                            >
                                                Ver demoras
                                            </a>
                                        )}
                            />
                    
                        <ChartCard
                            title="Consolidado de cumplimiento de proyectos en proceso"
                            handleOpen={() => {
                                setopenModalCumpliment(true);
                            }}
                        >
                        <ChartProyectCumpliment />
                        </ChartCard>
                        <ChartCard
                            title="Tipos de sesiones mas realizadas"
                            handleOpen={() => {
                                setopenModalType(true);
                            }}
                        >
                            <ChartSessionDistribution />
                        </ChartCard>
                        <ChartCard
                            title="Áreas más utilizadas"
                            handleOpen={() => {
                                setopenModalArea(true);
                            }}
                        >
                            <ChartMostUsedAreas />
                        </ChartCard>
                    </div>
                </div>
                <div className="col-md-3 list-data">
                    <ProyectsRanking />
                    <ClientsRanking />
                    <CoachsRanking />
                </div>
                </div>
               
               
                
            </>
        );
    };

    return (
        <div>
            <div className="container-page ">
                {loading ? (
                    <div className="center-admin-loader-page">
                        <Loader />
                    </div>
                ) : (
                    renderContent()
                )}
            </div>

            <Modal
                classNames={{ modal: 'modal-report' }}
                open={openModalCumpliment}
                onClose={() => {
                    setopenModalCumpliment(false);
                }}
                center
            >
                <ModalCumplimentReports />
            </Modal>

            <Modal
                classNames={{ modal: 'modal-report' }}
                open={openModalType}
                onClose={() => {
                    setopenModalType(false);
                }}
                center
            >
                <ModalTypeReports />
            </Modal>

            <Modal
                classNames={{ modal: 'modal-report' }}
                open={openModalArea}
                onClose={() => {
                    setopenModalArea(false);
                }}
                center
            >
                <ModalAreaReports />
            </Modal>

            <Modal
                classNames={{ modal: 'modal-size' }}
                open={openModalDelayed}
                onClose={() => {
                    setOpenModalDelayed(false);
                }}
                center
            >
                <ModalDelayedProyects />
            </Modal>
        </div>
    );
};

export default DashboardPage;
