import React, { useState } from 'react';
import { handleError, postData, putData } from '../../functions/services';
import Loader from '../common/Loader';

const ModalTeam = (props) => {
    const { data } = props;

    const [state, setState] = useState({
        name: data ? data.name : '',
        value: data ? data.value : '',
    });
    const [loading, setLoading] = useState(false);

    const handleChange = ({ target: { name, value } }) => {
        setState((prevState) => ({ ...prevState, [name]: value }));
    };

    const create = async () => {
        setLoading(true);

        try {
            await postData('teams', state, {});
            props.close();
            props.loadTable();
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const edit = async () => {
        setLoading(true);

        try {
            await putData('teams', data.id, state, {});
            props.close();
            props.loadTable();
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (data) {
            edit();
        } else {
            create();
        }
    };

    return (
        <div>
            <div className="p-2 modal-title">Área</div>

            {loading ? (
                <div className="center-admin-loader-modal">
                    <Loader />
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="form-row mt-3">
                        <div className="col-12">
                            <div className="form-group">
                                <label>Nombre</label>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    value={state.name}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <button className="btn btn-primary" type="submit" disabled={loading}>
                        Guardar
                    </button>
                </form>
            )}
        </div>
    );
};

export default ModalTeam;
