import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { getData, handleError } from '../../functions/services';
import Loader from '../common/Loader';

const ChartCumplimentBar = ({ data }) => {
    const renderChart = () => {
        const options = {
            legend: {
                display: false,
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                ],
            },
        };

        const chartData = {
            labels: ['0-25%', '26-50%', '51-75%', '76-100%'],
            datasets: [
                {
                    label: 'Cantidad',
                    data: data,
                    backgroundColor: [
                        'rgba(255, 159, 64, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 159, 64, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(75, 192, 192, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        };

        return <Bar data={chartData} options={options} />;
    };

    return renderChart();
};

export default ChartCumplimentBar;
