import React, { useEffect, useState } from 'react';
import { sessionTypes } from '../../constants/data';
import { getData, handleError, postData, putData } from '../../functions/services';
import Loader from '../common/Loader';
import useForm from '../../hooks/useForm';
import { sweetError } from '../../functions/sweetAlert';
import SignatureArea from '../SignatureArea';

const ModalCompleteSession = (props) => {
    const { data } = props;

    const { state, handleChange, handleCheck, handleMultiChange, handleChangeByName } = useForm({
        goal: data.goal ? data.goal : '',
        name: data.name ? data.name : '',
        compromise: data.compromise ? data.compromise : 1,
        cumpliment: data.cumpliment ? data.cumpliment : 1,
        valoration: data.valoration ? data.valoration : 1,
        comment: data.comment ? data.comment : '',
        anexPhoto: data.anexPhoto ? data.anexPhoto : false,
        anexVideo: data.anexVideo ? data.anexVideo : false,
        anexOther: data.anexOther ? data.anexOther : false,
        sessionItemActivities: [],
        signatureData: '',
    });

    const [openSignature, setOpenSignature] = useState(false);

    const [activities, setActivities] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            const req = await getData('activities');

            setActivities(req.data);

            if (!state.sessionItemActivities.length) {
                const stateData = {
                    sessionItemActivities: [{ activityId: req.data[0].id }],
                };
                handleMultiChange(stateData);
            }

            setLoading(false);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const create = async () => {
        setLoading(true);

        try {
            await postData('sessionItems', state);
            props.close();
            props.loadTable();
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const saveSignature = (value) => {
        handleChangeByName('signatureData', value);
    };

    const edit = async () => {
        if (!state.signatureData) {
            sweetError('Debes agregar tu firma');
            return;
        }
        setLoading(true);

        let formData = new FormData();

        Object.keys(state).forEach((key) => {
            if (typeof state[key] === 'object') {
                formData.append(key, JSON.stringify(state[key]));
            } else {
                formData.append(key, state[key]);
            }
        });

        try {
            await putData('sessionItems/complete', data.id, formData);
            props.close();
            props.loadTable();
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        edit();
    };

    const handleDynamicFields = (e, i) => {
        const values = [...state.sessionItemActivities];
        const { name, value } = e.target;
        values[i][name] = value;
        handleChangeByName('sessionItemActivities', values);
    };

    const AddField = () => {
        const values = [...state.sessionItemActivities];
        values.push({ activityId: activities[0].id });
        handleChangeByName('sessionItemActivities', values);
    };

    const RemoveField = (i) => {
        const values = [...state.sessionItemActivities];
        values.splice(i, 1);
        handleChangeByName('sessionItemActivities', values);
    };

    const activitiesFields = () => {
        return state.sessionItemActivities.map((field, i) => {
            const activitiesList = activities.map((val, j) => (
                <option value={val.id} key={j}>
                    {val.name}
                </option>
            ));

            return (
                <>
                    <div className="col-12" key={i}>
                        <div className="form-group">
                            <label>Actividad</label>
                            <select
                                name="activityId"
                                className="form-control"
                                value={field.activityId}
                                onChange={(e) => handleDynamicFields(e, i)}
                                required
                            >
                                {activitiesList}
                            </select>
                        </div>
                    </div>
                    <div className="mb-2 col-12">
                        {state.sessionItemActivities.length - 1 === i ? (
                            <button
                                className="btn btn-primary mr-2"
                                type="button"
                                onClick={() => AddField()}
                            >
                                +
                            </button>
                        ) : (
                            <></>
                        )}
                        {state.sessionItemActivities.length !== 1 ? (
                            <button
                                className="btn btn-danger"
                                type="button"
                                onClick={(e) => RemoveField(i)}
                            >
                                -
                            </button>
                        ) : (
                            <></>
                        )}
                    </div>
                </>
            );
        });
    };

    return (
        <div>
            <div className="p-2 modal-title">Sessión de Coaching</div>

            {loading ? (
                <div className="center-admin-loader-modal">
                    <Loader />
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="form-row mt-3">
                        <div className="col-12">
                            <div className="form-group">
                                <label>Objetivo</label>
                                <textarea
                                    name="goal"
                                    className="form-control"
                                    value={state.goal}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>

                        <div className="tac col-12 my-2">
                            <h6>Actividades</h6>
                        </div>

                        {activitiesFields()}

                        <div className="col-6">
                            <div className="form-group">
                                <label>Nombre del taller/Conferencia</label>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    value={state.name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Compromiso del coachee/mentee(%)</label>
                                <input
                                    type="number"
                                    name="compromise"
                                    className="form-control"
                                    value={state.compromise}
                                    onChange={handleChange}
                                    required
                                    min={1}
                                    max={100}
                                />
                                <small>1-100</small>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Cumplimiento de compromisos(%)</label>
                                <input
                                    type="number"
                                    name="cumpliment"
                                    className="form-control"
                                    value={state.cumpliment}
                                    onChange={handleChange}
                                    required
                                    min={1}
                                    max={100}
                                />
                                <small>1-100</small>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Valoración del proceso(%)</label>
                                <input
                                    type="number"
                                    name="valoration"
                                    className="form-control"
                                    value={state.valoration}
                                    onChange={handleChange}
                                    required
                                    min={1}
                                    max={100}
                                />
                                <small>1-100</small>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label>Comentarios</label>
                                <textarea
                                    name="comment"
                                    className="form-control"
                                    value={state.comment}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="tac col-12 my-2">
                            <h6>Anexos</h6>
                        </div>

                        <div className="col-4 tac">
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="anexPhoto"
                                    checked={state.anexPhoto}
                                    onChange={handleCheck}
                                />
                                <label className="form-check-label" htmlFor="anexPhoto">
                                    Fotos
                                </label>
                            </div>
                        </div>
                        <div className="col-4 tac">
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="anexVideo"
                                    checked={state.anexVideo}
                                    onChange={handleCheck}
                                />
                                <label className="form-check-label" htmlFor="anexVideo">
                                    Videos
                                </label>
                            </div>
                        </div>
                        <div className="col-4 tac">
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="anexOther"
                                    checked={state.anexOther}
                                    onChange={handleCheck}
                                />
                                <label className="form-check-label" htmlFor="anexOther">
                                    Otros
                                </label>
                            </div>
                        </div>

                        <div className="tac col-12 my-2">
                            <h6>Firma</h6>
                        </div>

                        <div className="col-12 tac">
                            <div>
                                <strong>Preview de la firma:</strong>
                            </div>
                            <button
                                className="btn btn-primary mt-2"
                                type="button"
                                onClick={() => {
                                    setOpenSignature(true);
                                }}
                            >
                                Firmar
                            </button>
                            <br />
                            {state.signatureData && (
                                <img
                                    src={state.signatureData}
                                    className="mt-1 center-50"
                                    alt="firma"
                                />
                            )}
                        </div>
                    </div>

                    <button className="btn btn-primary mt-3" type="submit" disabled={loading}>
                        Guardar
                    </button>
                </form>
            )}
            {openSignature && (
                <SignatureArea
                    close={() => {
                        setOpenSignature(false);
                    }}
                    save={saveSignature}
                />
            )}
        </div>
    );
};

export default ModalCompleteSession;
