import React from 'react';
import * as FaIcons from 'react-icons/fa';
import { Polar } from 'react-chartjs-2';

const LargeNumberCard = ({ title, completed, pending, late,button }) => {     
      const chartData = {
            labels: ['Completados', 'En proceso', 'Demoras'],
            datasets: [
                {
                    label: '',
                    data: [completed,pending,late],
                    backgroundColor: [
                        'rgba(40, 167, 69,0.7)',
                        'rgba(255, 207, 0,0.7)',
                        'rgb(220, 53, 69,0.7)',
                    ],                    
                    borderWidth: 1,
                },
            ],
        };
        const options = {
            legend: {
              display: true,
              position: "left"
            }
  };

    return (
        <div class="card border-success col-md-6">                                
                <div class="card-body">
                  <h6 class="card-title">{title} </h6>                                    
                  <Polar data={chartData} options={options}/>
                </div>    
                {button}           
        </div>
    )
    
};

export default LargeNumberCard;
