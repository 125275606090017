import { getRGB, getRGBA, randomInt } from './general';

export const buildChartDataColors = (length) => {
    const opacity = 0.2;
    let backgroundColors = [];
    let borderColors = [];

    for (let index = 0; index < length; index++) {
        const num1 = randomInt(255);
        const num2 = randomInt(255);
        const num3 = randomInt(255);

        backgroundColors.push(getRGBA(num1, num2, num3, opacity));
        borderColors.push(getRGB(num1, num2, num3));
    }

    return {
        backgroundColors,
        borderColors,
    };
};
