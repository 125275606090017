import { Route, Switch } from 'react-router-dom';
import './assets/styles/main.scss';
import Navbar from './components/Navbar';
import Activities from './pages/Activities';
import AdminCoachingItems from './pages/AdminCoachingItems';
import AdminPendingCoaching from './pages/AdminPendingCoachings';
import Anex from './pages/Anex';
import AprovedContract from './pages/AprovedContract';
import Coachs from './pages/Coachs';
import Companies from './pages/Companies';
import Mentees from './pages/Mentees';
import PendingContract from './pages/PendingContract';
import SignedContract from './pages/SignedContract';
import CoachPendingSessions from './pages/CoachPendingSessions';
import Teams from './pages/Teams';
import CoachCompletedSessions from './pages/CoachCompletedSessions';
import AdminCompleteCoachingItems from './components/AdminCompleteCoachingItems';
import AdminCompletedCoaching from './pages/AdminCompletedCoachings';
import DashboardPage from './pages/DashboardPage';
import AuthAdmin from './auth/AuthAdmin';
import AuthCoach from './auth/AuthCoach';

function Dashboard() {
    return (
        <div className="app">
            <Navbar />

            <div className="page-body active-padding" id="main-page">
                <Switch>
                    <AuthAdmin exact path="/admin/dashboard" component={DashboardPage} />

                    <AuthAdmin exact path="/admin/empresas" component={Companies} />

                    <AuthAdmin path="/admin/areas" component={Teams} exact />

                    <AuthAdmin path="/admin/coachs" component={Coachs} exact />

                    <AuthAdmin path="/admin/mentees" component={Mentees} exact />

                    <AuthAdmin path="/admin/actividades" component={Activities} exact />

                    <AuthAdmin
                        path="/admin/programas-pendientes"
                        component={AdminPendingCoaching}
                        exact
                    />

                    <AuthAdmin
                        path="/admin/programas-completados"
                        component={AdminCompletedCoaching}
                        exact
                    />

                    <AuthAdmin
                        path="/admin/programa-pendiente/:id"
                        component={AdminCoachingItems}
                        exact
                    />

                    <AuthCoach
                        path="/admin/coach/sesiones-pendientes"
                        component={CoachPendingSessions}
                        exact
                    />

                    <AuthCoach
                        path="/admin/coach/sesiones-completadas"
                        component={CoachCompletedSessions}
                        exact
                    />
                </Switch>
            </div>
        </div>
    );
}

export default Dashboard;
