import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { dualChartColors } from '../../constants/charts';
import { getData, handleError } from '../../functions/services';
import Loader from '../common/Loader';

const ChartCumplimentPie = ({ data }) => {
    const renderChart = () => {
        const options = {
            legend: {
                display: true,
            },
        };

        const chartData = {
            labels: ['% Pendiente', '% Completado'],
            datasets: [
                {
                    data: data,
                    backgroundColor: dualChartColors.backgroundColors,
                    borderColor: dualChartColors.borderColors,
                    borderWidth: 1,
                },
            ],
        };

        return <Pie data={chartData} options={options} />;
    };

    return renderChart();
};

export default ChartCumplimentPie;
