import React from 'react'

const ErrorText = (props) => {
    return (
        props.show?
        <div className='my-1 text-red'>
            {props.text}
        </div>
        :<></>
    )
}

export default ErrorText
