import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'chartjs-plugin-datalabels';
import firebase from 'firebase/app';

const firebaseConfig = {
    apiKey: 'AIzaSyDBWZ4fga1bjze2PlTsFusV1PCNOw6yfDs',
    authDomain: 'coaching-62357.firebaseapp.com',
    projectId: 'coaching-62357',
    storageBucket: 'coaching-62357.appspot.com',
    messagingSenderId: '73486316760',
    appId: '1:73486316760:web:9d6a262ebee7c08221d3ad',
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
