import React, { useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas'
import empty from '../assets/images/empty.jpeg'
import Loader from '../components/common/Loader';
import { getData, handleError, putData } from '../functions/services';
import {clientType as tipoCliente} from '../constants/data'
import * as FaIcons from "react-icons/fa";
import ErrorText from '../components/common/ErrorText';
import ContractPreview from '../components/ContractPreview';

const Firma = (props) => {

    const {match:{params:{uuid}}}=props

    const sigRef = useRef(null);

    const [signature, setSignature] = useState('')
    const [docCedula, setDocCedula] = useState('')

    const [docExt, setDocExt] = useState('')
    const [docRut, setDocRut] = useState('')
    const [docCeduRep, setDocCeduRep] = useState('')
    const [docVig, setDocVig] = useState('')

    const [contract, setContract] = useState('')

    const [loading, setLoading] = useState(true)
    const [errorSignature, setErrorSignature] = useState(false)
    const [page, setPage] = useState('form')

    const trimSignature = () => {

        if(!sigRef.current.isEmpty()){

            setSignature(sigRef.current.getTrimmedCanvas()
            .toDataURL('image/png'))

        }
        
    }

    const clearSignature = () => {

        sigRef.current.clear()
        setSignature('')
        
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if(!signature){
            setErrorSignature(true)
            return
        }

        let data = new FormData()

        if(docCedula){
            data.append('docCedula',docCedula)
        }

        if(docExt){
            data.append('docExt',docExt)
        }

        if(docRut){
            data.append('docRut',docRut)
        }

        if(docCeduRep){
            data.append('docCeduRep',docCeduRep)
        }

        if(docVig){
            data.append('docVig',docVig)
        }

        data.append('signature',signature)

        setLoading(true)

        try {

            await putData('contracts/signature',contract.id,data,{})
            setLoading(false)
            setPage('confirm')
            
        } catch (error) {
            setLoading(false)
            handleError(error)
        }

        

    }

    const loadContract = async () => {

        try {

            const req = await getData(`contracts/${uuid}`,{})
            if(req.data){
                setLoading(false)
                setContract(req.data)
            }else{
                props.history.push('/not-found')
            }
            
        } catch (error) {
            handleError(error)
        }
        
    }

    useEffect(() => {

        loadContract()
        
    }, [])

    const needDocuments = () => {

        const{clientType,docCeduRep,docCedula,docExt,docRut,docVig}=contract

        if(clientType===tipoCliente.Empresa){

            return( 
                !docCeduRep || 
                !docExt ||
                !docRut || 
                !docVig
            ) 

        }else{
            return docCedula===null
        }
        
    }

    const getDocFields = () => {

        const{clientType,docCeduRep,docCedula,docExt,docRut,docVig}=contract

        return(
            <>
            {
                needDocuments()?
                <>
                    <div className="tac my-5">
                        <h4>Documentos</h4>
                    </div>

                    <div className="form-row">

                        {
                            clientType===tipoCliente.Empresa?
                            <>
                            {
                                !docExt?
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Extracto de sociedad</label>
                                        <input type="file" className="form-control" 
                                        onChange={(e)=>setDocExt(e.target.files[0])}
                                        accept="image/*, .pdf" required />
                                        <small>Donde conste la personajeria juridica del representante legal</small>
                                    </div>
                                </div>
                                :<></>
                            }

                            {
                                !docRut?
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>RUT de la empresa</label>
                                        <input type="file" className="form-control" 
                                        onChange={(e)=>setDocRut(e.target.files[0])}
                                        accept="image/*, .pdf" required />
                                    </div>
                                </div>
                                :<></>
                            }
            
                            {
                                !docCeduRep?
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>CI del representante legal</label>
                                        <input type="file" className="form-control" 
                                        onChange={(e)=>setDocCeduRep(e.target.files[0])}
                                        accept="image/*, .pdf" required />
                                    </div>
                                </div>
                                :<></>
                            }

                            {
                                !docVig?
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Certificado de vigencia de la sociedad</label>
                                        <input type="file" className="form-control" 
                                        onChange={(e)=>setDocVig(e.target.files[0])}
                                        accept="image/*, .pdf" required />
                                    </div>
                                </div>
                                :<></>
                            }

                            </>
                            :

                            !docCedula?
                            <div className="col-12 col-sm-6">
                                <div className="form-group">
                                    <label>Cédula de identidad</label>
                                    <input type="file" className="form-control" 
                                    onChange={(e)=>setDocCedula(e.target.files[0])}
                                    accept="image/*, .pdf" required />
                                </div>
                            </div>
                            :<></>
                        }

                    </div>
                </>
                :
                <></>
            }
            </>
        )
        
    }

    const getSignForm = () => {

        return(
            <div className="firma-card card p-4">
                <div className="tac">
                    <h3>Firma de contrato</h3>
                    <h5>Cliente: {contract.clientName}</h5>
                </div>
                <div className="tac mb-3">
                    Firma en el area marcada
                </div>
                <SignatureCanvas 
                canvasProps={{style:{borderStyle: 'solid',height: '300px'}}}
                ref={sigRef}
                />

                <div className="row mt-3">

                    <div className="col-md-6 tac">

                        <div className="firma-button-area">

                            <button className="btn btn-primary m-1" onClick={trimSignature}>
                                Guardar Firma
                            </button>

                            <br/>

                            <button className="btn btn-primary m-1" onClick={clearSignature}>
                                Limpiar firma
                            </button>

                        </div>

                    </div>

                    <div className="col-md-6 tac">

                        Firma guardada

                        <br/>

                        {
                            signature?
                            <img src={signature} className="mt-1 firma-preview" alt="empty"/>
                            :
                            <img src={empty} className="mt-1" alt="empty"/>
                        }

                    </div>

                </div>

                <form onSubmit={handleSubmit}>

                    {getDocFields()}

                    <div className="mt-4">
                        <div className="tac">

                            <div className="my-1">
                            Al firmar y enviar el contrato el cliente autoriza a TECHPA para remitir información publicitaria, 
                            promocional y/o comercial.
                            </div>
                            
                            <button type="submit" className="btn btn-primary">
                                Enviar firma
                            </button>
                            <ErrorText show={errorSignature} text={'Guarda una firma antes de enviar'}/>

                        </div>
                        
                        <ContractPreview contract={contract} />

                        <div className="tac">
                            <button type="submit" className="btn btn-primary">
                                Enviar firma
                            </button>
                            <ErrorText show={errorSignature} text={'Guarda una firma antes de enviar'}/>
                        </div>

                    </div>

                </form>

            </div>
        )
        
    }

    return (
        <div className="firma-page">
            <div className="container-signature pt-5">

                {
                    loading?
                    <div className="center-sign-loader">
                        <Loader/>
                    </div>
                    :
                    page==='form'?
                        getSignForm()
                    :
                    <div className="full-center tac">

                        <FaIcons.FaCheckCircle className='confirm-sign-i'/>

                        <h3 className='mt-3'>Su firma se ha enviado con éxito</h3>

                    </div>
                }

            </div>
        </div>
    )
}

export default withRouter(Firma)
