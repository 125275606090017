import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import empty from '../assets/images/empty.jpeg';
import Loader from '../components/common/Loader';
import { getData, handleError, putData } from '../functions/services';
import * as FaIcons from 'react-icons/fa';
import ErrorText from '../components/common/ErrorText';
import AnexPreview from '../components/AnexPreview';

const AnexFirma = (props) => {
    const {
        match: {
            params: { uuid },
        },
    } = props;

    const sigRef = useRef(null);

    const [signature, setSignature] = useState('');

    const [anex, setAnex] = useState('');

    const [loading, setLoading] = useState(true);
    const [errorSignature, setErrorSignature] = useState(false);
    const [page, setPage] = useState('form');

    const trimSignature = () => {
        if (!sigRef.current.isEmpty()) {
            setSignature(sigRef.current.getTrimmedCanvas().toDataURL('image/png'));
        }
    };

    const clearSignature = () => {
        sigRef.current.clear();
        setSignature('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!signature) {
            setErrorSignature(true);
            return;
        }

        let data = new FormData();

        data.append('signature', signature);

        setLoading(true);

        try {
            await putData('anex/signature', anex.id, data, {});
            setLoading(false);
            setPage('confirm');
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const loadContract = async () => {
        try {
            const req = await getData(`anex/${uuid}/signature`, {});
            if (req.data) {
                setLoading(false);
                console.log(req.data);
                setAnex(req.data);
            } else {
                props.history.push('/not-found');
            }
        } catch (error) {
            handleError(error);
        }
    };

    useEffect(() => {
        loadContract();
    }, []);

    const getSignForm = () => {
        return (
            <div className="firma-card card p-4">
                <div className="tac">
                    <h3>Firma de anexo</h3>
                    <h5>Cliente: {anex && anex.contract.clientName}</h5>
                </div>
                <div className="tac mb-3">Firma en el area marcada</div>
                <SignatureCanvas canvasProps={{ style: { borderStyle: 'solid', height: '300px' } }} ref={sigRef} />

                <div className="row mt-3">
                    <div className="col-md-6 tac">
                        <div className="firma-button-area">
                            <button className="btn btn-primary m-1" onClick={trimSignature}>
                                Guardar Firma
                            </button>

                            <br />

                            <button className="btn btn-primary m-1" onClick={clearSignature}>
                                Limpiar firma
                            </button>
                        </div>
                    </div>

                    <div className="col-md-6 tac">
                        Firma guardada
                        <br />
                        {signature ? (
                            <img src={signature} className="mt-1 firma-preview" alt="empty" />
                        ) : (
                            <img src={empty} className="mt-1" alt="empty" />
                        )}
                    </div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="mt-4">
                        <div className="tac">
                            <div className="my-1">
                                Al firmar y enviar el contrato el cliente autoriza a TECHPA para remitir información
                                publicitaria, promocional y/o comercial.
                            </div>

                            <button type="submit" className="btn btn-primary">
                                Enviar firma
                            </button>
                            <ErrorText show={errorSignature} text={'Guarda una firma antes de enviar'} />
                        </div>

                        <AnexPreview anex={anex} />

                        <div className="tac">
                            <button type="submit" className="btn btn-primary">
                                Enviar firma
                            </button>
                            <ErrorText show={errorSignature} text={'Guarda una firma antes de enviar'} />
                        </div>
                    </div>
                </form>
            </div>
        );
    };

    return (
        <div className="firma-page">
            <div className="container-signature pt-5">
                {loading ? (
                    <div className="center-sign-loader">
                        <Loader />
                    </div>
                ) : page === 'form' ? (
                    getSignForm()
                ) : (
                    <div className="full-center tac">
                        <FaIcons.FaCheckCircle className="confirm-sign-i" />

                        <h3 className="mt-3">Su firma se ha enviado con éxito</h3>
                    </div>
                )}
            </div>
        </div>
    );
};

export default withRouter(AnexFirma);
