import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getUserData } from '../functions/services';

export const AuthRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (getUserData()) {
                    return <Component {...props} />;
                } else {
                    return <Redirect to="/" />;
                }
            }}
        />
    );
};

export default AuthRoute;
