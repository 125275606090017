import React from 'react';
import * as FaIcons from 'react-icons/fa';
import { userRole } from '../constants/data';

export const SidebarData = [
    {
        title: 'Dashboad',
        path: '/admin/dashboard',
        icon: <FaIcons.FaChartLine className="nav-icon" />,
        
        roles: [userRole.admin],
    },
    {
        title: 'Proyectos Pendientes',
        path: '/admin/programas-pendientes',
        icon: <FaIcons.FaClipboardList className="nav-icon" />,
        
        roles: [userRole.admin],
    },
    {
        title: 'Proyectos Completados',
        path: '/admin/programas-completados',
        icon: <FaIcons.FaClipboardCheck className="nav-icon" />,
        
        roles: [userRole.admin],
    },
    {
        title: 'Sesiones Pendientes',
        path: '/admin/coach/sesiones-pendientes',
        icon: <FaIcons.FaClipboardList className="nav-icon" />,
        
        roles: [userRole.coach],
    },
    {
        title: 'Sesiones Completadas',
        path: '/admin/coach/sesiones-completadas',
        icon: <FaIcons.FaClipboardCheck className="nav-icon" />,
        
        roles: [userRole.coach],
    },
    {
        title: 'Clientes',
        path: '/admin/empresas',
        icon: <FaIcons.FaBuilding className="nav-icon" />,
        
        roles: [userRole.admin],
    },
    {
        title: 'Áreas',
        path: '/admin/areas',
        icon: <FaIcons.FaUsers className="nav-icon" />,
        
        roles: [userRole.admin],
    },
    {
        title: 'Actividades',
        path: '/admin/actividades',
        icon: <FaIcons.FaTasks className="nav-icon" />,
        
        roles: [userRole.admin],
    },
    {
        title: 'Coachs / Mentores',
        path: '/admin/coachs',
        icon: <FaIcons.FaUserTie className="nav-icon" />,
        
        roles: [userRole.admin],
    },
    {
        title: 'Coachees / Mentees',
        path: '/admin/mentees',
        icon: <FaIcons.FaUserGraduate className="nav-icon" />,
        
        roles: [userRole.admin],
    },
];
