import React, { useEffect, useState } from 'react';
import { getTotalMen, getTotalPagoUni } from '../../functions/contract';
import { getData, handleError, postData, putData } from '../../functions/services';
import Loader from '../common/Loader';

const ModalContract = (props) => {
    const { data, contract } = props;

    const [detFields, setDetFields] = useState([
        {
            equipo: '',
            modelo: '',
            instala: 1,
            arrien: 1,
            venta: 1,
            migra: 1,
            inst: 1,
            actv: 1,
            plan: 1,
            seguro: 1,
            cantidad: 1,
        },
    ]);

    const [loading, setLoading] = useState(true);
    const [deviceArray, setDeviceArray] = useState([]);

    const handleDetFields = (e, i) => {
        const values = [...detFields];
        const { name, value } = e.target;
        values[i][name] = value;
        setDetFields(values);
    };

    const handleAddField = () => {
        const values = [...detFields];
        values.push({
            equipo: deviceArray[0].name,
            modelo: '',
            instala: 1,
            arrien: 1,
            venta: 1,
            actv: 1,
            plan: 1,
            seguro: 1,
            cantidad: 1,
        });
        setDetFields(values);
    };

    const handleRemoveField = (i) => {
        const values = [...detFields];
        values.splice(i, 1);
        setDetFields(values);
    };

    const create = async () => {
        let data = new FormData();

        data.append('anexDetails', JSON.stringify(detFields));
        data.append('contractId', contract);
        setLoading(true);

        try {
            await postData('anex', data, {});
            props.close();
            props.loadTable();
        } catch (error) {
            setLoading(false);
            console.log(error);
            handleError(error);
        }
    };

    const edit = async () => {
        let data = new FormData();

        data.append('anexDetails', JSON.stringify(detFields));
        data.append('contractId', contract);

        setLoading(true);

        try {
            await putData('anex', props.data.id, data, {});
            props.close();
            props.loadTable();
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (data) {
            edit();
        } else {
            create();
        }
    };

    const loadDevices = async () => {
        if (data) {
            setDetFields(data.anexDetails);
            setDeviceArray(data.anexDevices);
            setLoading(false);
        } else {
            try {
                const req = await getData('devices', {});

                setLoading(false);
                setDeviceArray(req.data);

                const values = [...detFields];
                values[0] = {
                    equipo: req.data[0].name,
                    modelo: '',
                    instala: 1,
                    arrien: 1,
                    venta: 1,
                    actv: 1,
                    plan: 1,
                    seguro: 1,
                    cantidad: 1,
                };

                setDetFields(values);
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        loadDevices();
    }, []);

    const detFieldsList = () => {
        return detFields.map((field, i) => {
            const devicesList = deviceArray.map((val, j) => (
                <option value={val.name} key={j}>
                    {val.name}
                </option>
            ));

            return (
                <div className="form-row" key={i}>
                    <div className="col-4">
                        <div className="form-group">
                            <label>Equipo</label>
                            <select
                                name="equipo"
                                className="form-control"
                                value={field.equipo}
                                onChange={(e) => handleDetFields(e, i)}
                                required
                            >
                                {devicesList}
                            </select>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="form-group">
                            <label>Modelo</label>
                            <input
                                type="text"
                                name="modelo"
                                className="form-control"
                                value={field.modelo}
                                onChange={(e) => handleDetFields(e, i)}
                                required
                            />
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="form-group">
                            <label>Cantidad</label>
                            <input
                                type="text"
                                name="cantidad"
                                className="form-control"
                                value={field.cantidad}
                                onChange={(e) => handleDetFields(e, i)}
                                required
                            />
                        </div>
                    </div>

                    <div className="col-12">
                        <hr />
                    </div>

                    <div className="col-4">
                        <div className="form-group">
                            <label>Instalacion</label>
                            <input
                                type="number"
                                name="instala"
                                className="form-control"
                                value={field.instala}
                                step="0.01"
                                onChange={(e) => handleDetFields(e, i)}
                                required
                            />
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="form-group">
                            <label>Venta</label>
                            <input
                                type="number"
                                name="venta"
                                className="form-control"
                                value={field.venta}
                                step="0.01"
                                onChange={(e) => handleDetFields(e, i)}
                                required
                            />
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="form-group">
                            <label>Activación</label>
                            <input
                                type="number"
                                name="actv"
                                className="form-control"
                                value={field.actv}
                                step="0.01"
                                onChange={(e) => handleDetFields(e, i)}
                                required
                            />
                        </div>
                    </div>

                    <div className="col-12">
                        <hr />
                    </div>

                    <div className="col-4">
                        <div className="form-group">
                            <label>Arriendo</label>
                            <input
                                type="number"
                                name="arrien"
                                className="form-control"
                                value={field.arrien}
                                step="0.01"
                                onChange={(e) => handleDetFields(e, i)}
                                required
                            />
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="form-group">
                            <label>Plan</label>
                            <input
                                type="number"
                                name="plan"
                                className="form-control"
                                value={field.plan}
                                step="0.01"
                                onChange={(e) => handleDetFields(e, i)}
                                required
                            />
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="form-group">
                            <label>Seguro</label>
                            <input
                                type="number"
                                name="seguro"
                                className="form-control"
                                value={field.seguro}
                                step="0.01"
                                onChange={(e) => handleDetFields(e, i)}
                                required
                            />
                        </div>
                    </div>

                    <div className="mb-2 col-12">
                        {detFields.length - 1 === i ? (
                            <button className="btn btn-primary mr-2" type="button" onClick={() => handleAddField()}>
                                +
                            </button>
                        ) : (
                            <></>
                        )}
                        {detFields.length !== 1 ? (
                            <button className="btn btn-danger" type="button" onClick={(e) => handleRemoveField(i)}>
                                -
                            </button>
                        ) : (
                            <></>
                        )}
                    </div>

                    {detFields.length !== 1 && detFields.length !== i + 1 ? (
                        <div className="col-12">
                            <hr className="separator" />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            );
        });
    };

    return (
        <div>
            <div className="p-2 modal-title">Formulario de anexo</div>
            {loading ? (
                <div className="center-admin-loader-modal">
                    <Loader />
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="tac modal-subtitle my-3">Detalles del anexo</div>

                    {detFieldsList()}

                    <div className="form-row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Total pago único </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={getTotalPagoUni(detFields)}
                                    disabled
                                />
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-group">
                                <label>Total mensual plan </label>
                                <input type="text" className="form-control" value={getTotalMen(detFields)} disabled />
                            </div>
                        </div>
                    </div>

                    <button className="btn btn-primary" type="submit">
                        Guardar
                    </button>
                </form>
            )}
        </div>
    );
};

export default ModalContract;
