import React from 'react';

const ChartCard = ({ children, title, handleOpen }) => {
    return (
        <div class="card border-success col-md-6">                
                <div class="card-body">
                  <h6 class="card-title">{title}</h6>                  
                    {children}
                    <a href="#" className="center-fit" onClick={handleOpen}>Ver Detalles</a>
                </div>               
        </div>
       
    );
};

export default ChartCard;
