import React, { useEffect, useState } from 'react';
import { Countries } from '../../constants/data';
import { allData, getData, handleError, postData, putData } from '../../functions/services';
import Loader from '../common/Loader';
import useForm from '../../hooks/useForm';
import { arrayByNameAsc } from '../../functions/general';
import { getCitiesByCountry } from '../../functions/Countries';

const CountriesData = arrayByNameAsc(Countries);

const ModalCoaching = (props) => {
    const { data } = props;

    const {
        state,
        handleChange,
        handleChangeByName,
        handleMultiChange,
        handleCheckIdList,
    } = useForm({
        country: data ? data.country : CountriesData[0].name,
        city: data ? data.city : getCitiesByCountry(CountriesData[0].name)[0].name,
        companyId: data ? data.companyId : '',
        checkedTeams: data ? data.teams.map((val) => val.id) : [],
        number: data ? data.number : 1,
    });

    const [cities, setCities] = useState(
        data ? getCitiesByCountry(data.country) : getCitiesByCountry(CountriesData[0].name)
    );

    const [companies, setCompanies] = useState([]);
    const [teams, setTeams] = useState([]);

    const [loading, setLoading] = useState(true);

    const loadData = async () => {
        try {
            const req = await allData([getData('companies'), getData('teams')]);

            setCompanies(req[0].data);

            setTeams(req[1].data);

            if (!data) {
                const stateData = {
                    companyId: req[0].data[0].id,
                    teamId: req[1].data[0].id,
                };

                handleMultiChange(stateData);
            }

            setLoading(false);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (!loading) {
            const citiesData = getCitiesByCountry(state.country);
            setCities(citiesData);
            handleChangeByName('city', citiesData[0].name);
        }
    }, [state.country]);

    const create = async () => {
        setLoading(true);

        try {
            await postData('sessions', state);
            props.close();
            props.loadTable();
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const edit = async () => {
        setLoading(true);

        try {
            await putData('sessions', data.id, state);
            props.close();
            props.loadTable();
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (data) {
            edit();
        } else {
            create();
        }
    };

    const countriesList = () => {
        return arrayByNameAsc(Countries).map((val, i) => (
            <option value={val.name} key={i}>
                {val.name}
            </option>
        ));
    };

    const citiesList = () => {
        return arrayByNameAsc(cities).map((val, i) => (
            <option value={val.name} key={i}>
                {val.name}
            </option>
        ));
    };

    const companiesList = () => {
        return companies.map((val, i) => (
            <option value={val.id} key={i}>
                {val.name}
            </option>
        ));
    };

    const teamsList = () => {
        return teams.map((val) => (
            <div className="col-3 tac" key={val.id}>
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="checkedTeams"
                        checked={state.checkedTeams.includes(val.id)}
                        onChange={handleCheckIdList}
                        value={val.id}
                    />
                    <label className="form-check-label">{val.name}</label>
                </div>
            </div>
        ));
    };

    return (
        <div>
            <div className="p-2 modal-title">Proyecto</div>

            {loading ? (
                <div className="center-admin-loader-modal">
                    <Loader />
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="form-row mt-3">
                        <div className="col-6">
                            <div className="form-group">
                                <label>País</label>
                                <select
                                    name="country"
                                    className="form-control"
                                    value={state.country}
                                    onChange={(e) => handleChange(e)}
                                    required
                                >
                                    {countriesList()}
                                </select>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Ciudad</label>
                                <select
                                    name="city"
                                    className="form-control"
                                    value={state.city}
                                    onChange={handleChange}
                                    required
                                >
                                    {citiesList()}
                                </select>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Cliente</label>
                                <select
                                    name="companyId"
                                    className="form-control"
                                    value={state.companyId}
                                    onChange={handleChange}
                                    required
                                >
                                    {companiesList()}
                                </select>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Cantidad de sesiones</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    name="number"
                                    value={state.number}
                                    onChange={handleChange}
                                    min={1}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <h5 className="tac mt-3">Áreas</h5>

                    <div className="row my-3">{teamsList()}</div>

                    <button className="btn btn-primary" type="submit" disabled={loading}>
                        Guardar
                    </button>
                </form>
            )}
        </div>
    );
};

export default ModalCoaching;
