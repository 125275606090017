import React, { useEffect, useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import Modal from 'react-responsive-modal';
import { getData, handleError } from '../functions/services';
import Loader from './common/Loader';
import ModalClientsRanking from './modal/ModalClientsRanking';

const ClientsRanking = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const onCloseModal = () => {
        setOpenModal(false);
    };

    const loadData = async () => {
        try {
            const req = await getData('dashboard/clients-ranking', {});
            setData(req.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const renderTable = () => {
        const tableData = data.map((val, i) => {
            return (
                <tr key={i}>
                    <td className="tac">{i + 1}</td>
                    <td>{val.name}</td>
                    <td className="tac">{val.sessionCount}</td>                    
                </tr>
            );
        });

        return (
            <>
                <table className="table table-striped table-sm">
                    <thead className="tac">
                        <tr>
                            <th>#</th>
                            <th>Nombre</th>
                            <th>Sesiones</th>                            
                        </tr>
                    </thead>
                    <tbody>{tableData}</tbody>
                </table>
                <div className="tac">
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            setOpenModal(true);
                        }}
                    >
                        Ver detalles
                    </a>
                </div>
            </>
        );
    };

    return (
        <div class="card border-success ">                
                <div class="card-body">
                  <h6>{'Mejores Clientes'}</h6>
                    {loading ? (
                        <div className="center-flex">
                            <Loader />
                        </div>
                    ) : (
                        renderTable()
                    )}
                </div>  
                <Modal
                classNames={{ modal: 'modal-size' }}
                open={openModal}
                onClose={onCloseModal}
                center>
                <ModalClientsRanking close={onCloseModal} />
            </Modal>             
        </div>        
    );

};

export default ClientsRanking;
