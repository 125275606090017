import { format } from 'date-fns';
import React from 'react';
import logo from '../assets/images/logo.JPG';
import { getTotalMen, getTotalPagoUni } from '../functions/contract';

const AnexPreview = (props) => {
    const { anex } = props;
    const date = anex ? format(new Date(anex.createdAt), 'dd-MM-yyyy') : '';
    const listDetails = anex ? anex.anexDetails : [];
    const listDevices = anex ? anex.anexDevices : [];

    return (
        <div>
            <div className="tac my-5">
                <h4>Preview del anexo</h4>
            </div>

            <div className="row">
                <div className="col-6">
                    <table className="table mt-3 contract-table table-responsive-md">
                        <thead>
                            <tr>
                                <th>Fecha</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{date}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="col-6">
                    <img className="float-right" src={logo} alt="logo" />
                </div>
            </div>

            <div className="tac mb-3 font-weight-bold">DETALLES</div>

            <table className="table contract-table table-responsive-md">
                <thead>
                    <tr>
                        <th>CANT</th>
                        <th>EQUIPO</th>
                        <th>MODELO</th>
                        <th>INSTALACIÓN</th>
                        <th>VENTA</th>
                        <th>ARRIENDO</th>
                        <th>ACTIVACIÓN</th>
                        <th>PLAN</th>
                        <th>SEGURO</th>
                    </tr>
                </thead>
                <tbody>
                    {listDetails.map((det, i) => (
                        <tr key={i}>
                            <td>{parseInt(det.cantidad)}</td>
                            <td>{det.equipo}</td>
                            <td>{det.modelo}</td>
                            <td>{det.instala}</td>
                            <td>{det.venta}</td>
                            <td>{det.arrien}</td>
                            <td>{det.actv}</td>
                            <td>{det.plan}</td>
                            <td>{det.seguro}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <table className="table tableUp contract-table">
                <thead>
                    <tr>
                        <th className="tac">TOTAL PAGO ÚNICO</th>
                        <th className="tac">TOTAL MENSUAL PLAN</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{getTotalPagoUni(listDetails)}</td>
                        <td>{getTotalMen(listDetails)}</td>
                    </tr>
                </tbody>
            </table>

            <p>
                <strong>Valor de Equipos AVL y Accesorios:</strong>
            </p>

            <div className="row">
                <div className="col-4">
                    <table className="table contract-table">
                        <thead>
                            <tr>
                                <th>EQUIPO</th>
                                <th>VALOR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listDevices.map((dev, i) => (
                                <tr key={i}>
                                    <td>{dev.name}</td>
                                    <td>{dev.value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AnexPreview;
