import React, { useState } from 'react';
import logo from '../assets/images/logo.JPG';
import { handleError, postData, setUserData } from '../functions/services';

const Login = (props) => {
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');

    const submit = async (e) => {
        e.preventDefault();

        try {
            const json = {
                email,
                password: pass,
            };
            const req = await postData('auth/signin/', json, {});
            setUserData(req.data);
            props.history.push('/admin');
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    return (
        <div className="login-page">
            <div className="col-9 frontimage">     
                <img src="{logo}" alt="logo" />       
            </div>
            <div className="col-3">
                <form onSubmit={submit} className="mt-4">
                    <div className="container">
                        
                            <div className="form-row loginform">
                                <div className="tiltelogin"><strong>Inicie sesión para acceder a su cuenta</strong></div>
                                <hr/>
                                <div className="col-12">
                                    <div className="form-group ">
                                        <label className=" font-weight-bold">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className=" font-weight-bold">
                                            Contraseña
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            value={pass}
                                            onChange={(e) => setPass(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        
                    </div>

                    <div className="tac pb-4 mt-2">
                        <button className="btn btn-danger" type="submit">
                            Ingresar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
