import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Loader from '../components/common/Loader';
import { deleteData, getData, handleError } from '../functions/services';
import DataTable from 'react-data-table-component';
import * as FaIcons from 'react-icons/fa';
import { sweetQuestion } from '../functions/sweetAlert';
import ModalCompany from '../components/modal/ModalCompany';
import { customTableStyles } from '../constants/data';

const Companies = () => {
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [modalData, setModalData] = useState('');

    const onOpenModal = (data) => {
        setModalData(data);
        setOpenModal(true);
    };
    const onCloseModal = () => setOpenModal(false);

    const loadTable = async () => {
        try {
            setLoading(true);
            const req = await getData('companies', {});
            setLoading(false);
            setData(req.data);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const tableData = () => {
        const columns = [
            {
                name: 'Nombre',
                selector: 'name',
                sortable: true,
            },
            {
                name: 'Tipo',
                selector: 'type',
                sortable: true,
            },
            {
                name: 'Acciones',
                selector: 'actions',
                center: true,
            },
        ];

        let tableArray = [];

        searchArray().forEach((val) => {
            const json = {
                id: val.id,
                name: val.name,
                type: val.type,
                actions: (
                    <>
                        <span
                            className="pointer"
                            onClick={() => onOpenModal(val)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="editar"
                        >
                            <FaIcons.FaPencilAlt className="table-icon" />
                        </span>
                        <span
                            className="pointer"
                            onClick={() => onDelete(val.id)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="eliminar"
                        >
                            <FaIcons.FaTrash className="table-icon" />
                        </span>
                    </>
                ),
            };

            tableArray.push(json);
        });

        return (
            <DataTable
                customStyles={customTableStyles}
                columns={columns}
                data={tableArray}
                noHeader
                pagination
            />
        );
    };

    const onDelete = async (id) => {
        const question = await sweetQuestion('Quieres eliminar este cliente?', null);
        if (question.isConfirmed) {
            try {
                await deleteData('companies', id);
                loadTable();
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
    };

    const searchArray = () => {
        let keys = ['name'];
        const dataCopy = [...data];

        return dataCopy.filter((value) => {
            return keys.reduce((acum, key) => {
                return acum || value[key].toString().toLowerCase().includes(search.toLowerCase());
            }, false);
        });
    };

    useEffect(() => {
        loadTable();
    }, []);

    return (
        <div>
            <div className="container-page ">
                <div className="page-title">Clientes</div>

                <div className="card p-3 mt-3 position-static">
                    <div className="d-flex mb-1">
                        <input
                            type="text"
                            className="form-control page-search mr-auto"
                            onChange={(e) => setSearch(e.target.value)}
                        />

                        <button className="btn btn-primary" onClick={() => onOpenModal('')}>
                            Crear Cliente
                        </button>
                    </div>

                    {loading ? (
                        <div className="center-admin-loader-page">
                            <Loader />
                        </div>
                    ) : (
                        tableData()
                    )}
                </div>
            </div>

            <Modal
                classNames={{ modal: 'modal-size' }}
                open={openModal}
                onClose={onCloseModal}
                center
            >
                <ModalCompany data={modalData} close={onCloseModal} loadTable={loadTable} />
            </Modal>
        </div>
    );
};

export default Companies;
