import React, { useState } from 'react';
import { handleError, postData, putData } from '../../functions/services';
import Loader from '../common/Loader';
import * as FaIcons from 'react-icons/fa';
import { formatDateToLocal } from '../../functions/dates';

const ModalSessionDetails = ({ data }) => {
    const {
        session: { company },
        city,
        country,
        user: coach,
        goal,
        activities,
        compromise,
        cumpliment,
        valoration,
        comment,
        anexPhoto,
        anexOther,
        anexVideo,
        signature,
        name: sessionName,
        date,
        number,
        mentees,
    } = data;

    console.log(data);

    const getAnexIcon = (val) => {
        return val ? (
            <FaIcons.FaCheckCircle className="exist-icon" />
        ) : (
            <FaIcons.FaTimesCircle className="no-exist-icon" />
        );
    };

    return (
        <div>
            <div className="p-2 modal-title">Detalles de la sessión</div>

            <div className="row mt-3">
                <div className="col-md-4">
                    <span className="bold">Fecha:</span>
                    {` ${city},`}
                    {formatDateToLocal(date)}
                </div>

                <div className="col-md-4">
                    <span className="bold">Cliente:</span> {company.name}
                </div>
                <div className="col-md-4">
                    <span className="bold"># de sesión:</span> {number}
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-md-4">
                    <span className="bold">Coach/Mentor:</span> {coach.name}
                </div>
                <div className="col-md-4">
                    <span className="bold">País:</span> {country}
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-md-12">
                    <span className="bold">Coachees/Mentees:</span>{' '}
                    {mentees.map((val) => val.name).join(',')}
                </div>
            </div>

            <hr />

            <div className="row mt-2">
                <div className="col-md-6">
                    <span className="bold">Objetivo:</span>
                    <br />
                    {goal}
                </div>
                <div className="col-md-6">
                    <span className="bold">Nombre del taller/Conferencia:</span>
                    <br />
                    {sessionName ? sessionName : 'N/A'}
                </div>
            </div>

            <table class="table activities-table mt-4">
                <thead>
                    <tr>
                        <th scope="col">Actividades Realizadas</th>
                    </tr>
                </thead>
                <tbody>
                    {activities.map((val, i) => {
                        return (
                            <tr key={i}>
                                <td>{val.name}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <hr />

            <h5 className="tac">Calificación del coach/mentor</h5>

            <div className="row mt-3">
                <div className="col-md-6">
                    <span className="bold">Compromiso del coachee/mentee:</span> {`${compromise}%`}
                </div>
                <div className="col-md-6">
                    <span className="bold">Cumplimiento de compromisos:</span> {`${cumpliment}%`}
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-md-6">
                    <span className="bold">Valoración del proceso:</span> {`${valoration}%`}
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-md-12">
                    <span className="bold">Comentario:</span> {comment}
                </div>
            </div>

            <h5 className="tac my-3">Anexos</h5>

            <div className="row mt-3 tac">
                <div className="col-md-4">
                    <span className="bold">Fotos:</span>
                    {getAnexIcon(anexPhoto)}
                </div>

                <div className="col-md-4">
                    <span className="bold">Vídeos:</span>
                    {getAnexIcon(anexVideo)}
                </div>

                <div className="col-md-4">
                    <span className="bold">Otros:</span>
                    {getAnexIcon(anexOther)}
                </div>
            </div>

            <img src={signature} className="center-50 signature-border" alt="firma" />

            <h5 className="tac my-3">Firma</h5>
        </div>
    );
};

export default ModalSessionDetails;
