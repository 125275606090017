import Swal from 'sweetalert2';

export const sweetQuestion = (title, html) => {
    return Swal.fire({
        title: title,
        html: html,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar',
    });
};

export const sweetError = (title, text) => {
    return Swal.fire({
        title,
        text,
        icon: 'error',
    });
};

export const sweetAlertToast = (text) => {
    Swal.fire({
        icon: 'success',
        title: text,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
    });
};
