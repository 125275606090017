import React, { useEffect, useState } from 'react';
import { typeOptionList, typeOptions, sessionTypes } from '../../constants/data';
import { getData, handleError } from '../../functions/services';
import Loader from '../common/Loader';
import ChartDonut from '../charts/ChartDonut';
import { buildChartDataColors } from '../../functions/charts';
import { dualChartColors } from '../../constants/charts';

const chartColors = buildChartDataColors(sessionTypes.length);

const ModalTypeReports = () => {
    const [option, setOption] = useState(typeOptions.Todas);
    const [selection, setSelection] = useState('');
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState('');
    const [chartData, setChartData] = useState('');
    const [listData, setListData] = useState('');
    const [chartLabels, setChartLabels] = useState('');
    const [detailData, setDetailData] = useState('');

    const loadAllTypes = async () => {
        setLoading(true);
        try {
            const req = await getData('reports/all-session-types', {});
            setData(req.data);
            setChartData(req.data.sessions.map((val) => val.percent.toFixed(2)));
            setListData(req.data.sessions.map((val) => val.SessionCount));
            setChartLabels(req.data.sessions.map((val) => `% ${val.sessionType}`));
            setLoading(false);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const loadDetailType = async () => {
        setLoading(true);
        try {
            const req = await getData(`reports/sessions-by-type/${selection}`, {});
            setDetailData(req.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    useEffect(() => {
        if (option === typeOptions.Todas) {
            loadAllTypes();
        }
        if (option === typeOptions.Detalle && selection) {
            loadDetailType();
        }
    }, [option, selection]);

    const renderOptions = () => {
        const radiOptions = typeOptionList.map((val, i) => {
            return (
                <div className="form-check form-check-inline" key={i}>
                    <input
                        className="form-check-input"
                        type="radio"
                        name="cumplimentOption"
                        value={val}
                        checked={val === option}
                        onChange={(e) => {
                            setSelection('');
                            setOption(e.target.value);
                        }}
                    />
                    <label className="form-check-label">{val}</label>
                </div>
            );
        });

        return (
            <div>
                <div>{radiOptions}</div>

                {option === typeOptions.Detalle && (
                    <div className="mt-2">
                        <select
                            name="selection"
                            className="form-control"
                            value={selection}
                            onChange={(e) => {
                                setSelection(e.target.value);
                            }}
                        >
                            <option value="">Escoge una opción</option>
                            {sessionTypes.map((val, i) => {
                                return (
                                    <option value={val} key={i}>
                                        {`${val}`}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                )}
            </div>
        );
    };

    const renderContent = () => {
        if (option === typeOptions.Todas) {
            return renderAllTypes();
        } else {
            return renderDetailType();
        }
    };

    const renderAllTypes = () => {
        const detailList = data.sessions.map((val, i) => {
            return (
                <div key={i}>
                    <div className="my-2 d-flex">
                        <span
                            className="circle-list"
                            style={{ backgroundColor: chartColors.backgroundColors[i] }}
                        ></span>
                        <strong>{val.sessionType}:</strong>
                        <span className="ml-auto">{` ${listData[i]}`}</span>
                    </div>
                    <hr className="m-0" />
                </div>
            );
        });

        return (
            <>
            <div class="row">
              <div className="col-md-12"><br/></div>

                

                <div className="col-md-6">
                    <h4 className="tac">Cantidad de sesiones</h4>

                    <div>
                        <div className="my-4 d-flex">
                            <span
                                className="circle-list"
                                style={{
                                    backgroundColor:
                                        option === typeOptions.Todas
                                            ? 'black'
                                            : dualChartColors.backgroundColors[0],
                                }}
                            ></span>
                            <h5>Sesiones Totales:</h5>
                            <span className="ml-auto">{` ${data.total}`}</span>
                        </div>
                    </div>

                    {detailList}
                </div>
                <div class="col-md-6">
                    <ChartDonut data={chartData} chartColors={chartColors} labels={chartLabels} />
                </div>

            </div>

            </>
        );
    };

    const renderDetailType = () => {
        if (!selection || !detailData) {
            return;
        }

        return (
            <>
              <div class="row">
              <div className="col-md-12"><br/></div>
                <div className="col-md-6">
                    <h4 className="tac">Detalles de sesiones</h4>

                    <div>
                        <div className="my-4 d-flex">
                            <span
                                className="circle-list"
                                style={{
                                    backgroundColor:
                                        option === typeOptions.Todas
                                            ? 'black'
                                            : dualChartColors.backgroundColors[0],
                                }}
                            ></span>
                            <h5>Sesiones Totales:</h5>
                            <span className="ml-auto">{` ${detailData.total}`}</span>
                        </div>
                    </div>

                    <div>
                        <div className="my-2 d-flex">
                            <span
                                className="circle-list"
                                style={{
                                    backgroundColor: dualChartColors.backgroundColors[1],
                                }}
                            ></span>
                            <strong>{selection}:</strong>
                            <span className="ml-auto">{` ${detailData.sessions}`}</span>
                        </div>
                    </div>
                </div>

                 <div class="col-md-6">
                <ChartDonut
                    data={[(100 - detailData.percent).toFixed(2), detailData.percent.toFixed(2)]}
                    chartColors={dualChartColors}
                    labels={['% Resto de áreas', '% Área seleccionada']}
                />
                </div>

                </div>
            </>
        );
    };

    return (
        <div>
            <div className="p-2 modal-title">Reportes de sesiones por tipo</div>

            <div className="mt-4">{renderOptions()}</div>

            {loading ? (
                <div className="center-admin-loader-modal">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="col-md-12 col-xs-12">{renderContent()}</div>
                </>
            )}
        </div>
    );
};

export default ModalTypeReports;
