export const compareNameAsc = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
        comparison = 1;
    } else if (nameA < nameB) {
        comparison = -1;
    }
    return comparison;
};

export const arrayByNameAsc = (array) => {
    return array.sort(compareNameAsc);
};

export const randomInt = (max) => {
    let value = Math.random() * max;

    return Math.round(value);
};

export const randomRGB = () => {
    return `rgb(${randomInt(255)}, ${randomInt(255)}, ${randomInt(255)})`;
};

export const randomRGA = (opacity) => {
    return `rgba(${randomInt(255)}, ${randomInt(255)}, ${randomInt(255)}, ${opacity})`;
};

export const getRGB = (value1, value2, value3) => {
    return `rgb(${value1}, ${value2}, ${value3})`;
};

export const getRGBA = (value1, value2, value3, opacity) => {
    return `rgb(${value1}, ${value2}, ${value3}, ${opacity})`;
};

export const getRandomNumbers = (number) => {
    let numberArray = [];

    for (let index = 0; index < number; index++) {
        numberArray.push(randomInt(100));
    }

    return numberArray;
};
