import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import empty from '../assets/images/empty.jpeg';
import Loader from '../components/common/Loader';
import { getData, handleError, postData, putData } from '../functions/services';
import {
    clientType as tipoCliente,
    coachQuestions,
    questionValues,
    sessionQuestions,
    logisticQuestions,
    sessionStatus,
} from '../constants/data';
import * as FaIcons from 'react-icons/fa';
import ErrorText from '../components/common/ErrorText';
import ContractPreview from '../components/ContractPreview';
import useForm from '../hooks/useForm';
import SignatureArea from '../components/SignatureArea';
import { formatDateToLocal } from '../functions/dates';
import { sweetError } from '../functions/sweetAlert';
import { set } from 'date-fns/esm';

const RateSession = (props) => {
    const {
        match: {
            params: { uuid },
        },
    } = props;

    const { state, handleChange, handleCheck, handleMultiChange, handleChangeByName } = useForm({
        coach_cono: '',
        coach_habi_com: '',
        coach_habi_pre: '',
        coach_iden: '',
        coach_ori: '',
        coach_herra: '',
        coach_pun: '',
        coach_pre: '',
        coach_progra: '',
        coach_res: '',

        ses_cum: '',
        ses_tema: '',
        ses_log: '',
        ses_rela: '',

        logi_org: '',
        logi_hor: '',
        logi_sal: '',
        logi_ate: '',
        logi_ent: '',

        name: '',
        signatureData: '',
        comment: '',
    });

    const [loading, setLoading] = useState(true);
    const [data, setSession] = useState('');
    const [page, setPage] = useState('form');
    const [openSignature, setOpenSignature] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!state.signatureData) {
            sweetError('Debes agregar tu firma');
            return;
        }

        setLoading(true);

        let formData = new FormData();

        Object.keys(state).forEach((key) => {
            if (typeof state[key] === 'object') {
                formData.append(key, JSON.stringify(state[key]));
            } else {
                formData.append(key, state[key]);
            }
        });

        try {
            await postData('sessionRates', formData);
            setPage('confirm');
            setLoading(false);
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const saveSignature = (value) => {
        handleChangeByName('signatureData', value);
    };

    const loadSession = async () => {
        try {
            const req = await getData(`sessionItems/rate/${uuid}`, {});
            if (req.data) {
                setLoading(false);
                setSession(req.data);
                handleChangeByName('sessionItemId', req.data.id);
            } else {
                props.history.push('/not-found');
            }
        } catch (error) {
            handleError(error);
        }
    };

    useEffect(() => {
        loadSession();
    }, []);

    const renderCoachQuestions = () => {
        return coachQuestions.map((question, i) => {
            return (
                <div className="mt-3" key={i}>
                    <h6>{question.text}</h6>

                    {questionValues.map((value) => {
                        return (
                            <div
                                className="custom-control custom-radio custom-control-inline"
                                key={value}
                            >
                                <input
                                    type="radio"
                                    id={`${question.name}${value}`}
                                    name={`${question.name}`}
                                    className="custom-control-input"
                                    value={value}
                                    checked={parseInt(state[question.name]) === value}
                                    onChange={handleChange}
                                    required
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor={`${question.name}${value}`}
                                >
                                    {value}
                                </label>
                            </div>
                        );
                    })}
                </div>
            );
        });
    };

    const renderSessionQuestions = () => {
        return sessionQuestions.map((question, i) => {
            return (
                <div className="mt-3" key={i}>
                    <h6>{question.text}</h6>

                    {questionValues.map((value) => {
                        return (
                            <div
                                className="custom-control custom-radio custom-control-inline"
                                key={value}
                            >
                                <input
                                    type="radio"
                                    id={`${question.name}${value}`}
                                    name={`${question.name}`}
                                    className="custom-control-input"
                                    value={value}
                                    checked={parseInt(state[question.name]) === value}
                                    onChange={handleChange}
                                    required
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor={`${question.name}${value}`}
                                >
                                    {value}
                                </label>
                            </div>
                        );
                    })}
                </div>
            );
        });
    };

    const renderLogisticQuestions = () => {
        return logisticQuestions.map((question, i) => {
            return (
                <div className="mt-3" key={i}>
                    <h6>{question.text}</h6>

                    {questionValues.map((value) => {
                        return (
                            <div
                                className="custom-control custom-radio custom-control-inline"
                                key={value}
                            >
                                <input
                                    type="radio"
                                    id={`${question.name}${value}`}
                                    name={`${question.name}`}
                                    className="custom-control-input"
                                    value={value}
                                    checked={parseInt(state[question.name]) === value}
                                    onChange={handleChange}
                                    required
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor={`${question.name}${value}`}
                                >
                                    {value}
                                </label>
                            </div>
                        );
                    })}
                </div>
            );
        });
    };

    const getSignForm = () => {
        if (data) {
            const {
                session: { country, company },
                user: coach,
                goal,
                activities,
                compromise,
                cumpliment,
                valoration,
                comment,
                anexPhoto,
                anexOther,
                anexVideo,
                signature,
                team,
                name: sessionName,
                date,
                city,
                mentees,
            } = data;

            return (
                <div className="firma-card card p-4">
                    <div className="tac">
                        <h3>Califica la sessión</h3>
                    </div>

                    {data && (
                        <>
                            <div className="row mt-3">
                                <div className="col-md-4">
                                    <div className="signature-details-m">
                                        <span className="bold">Fecha:</span>
                                        {` ${city},`}
                                        {formatDateToLocal(date)}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="signature-details-m">
                                        <span className="bold">Cliente:</span> {company.name}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="signature-details-m">
                                        <span className="bold">Área:</span> {team.name}
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-4">
                                    <div className="signature-details-m">
                                        <span className="bold">Coach:</span> {coach.name}
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="signature-details-m2">
                                        <span className="bold">Coachees/Mentees:</span>{' '}
                                        {mentees.map((val) => val.name).join(',')}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    <form onSubmit={handleSubmit}>
                        <div className="tac mt-4">
                            <div className="tac my-2">
                                <strong>
                                    Califica tomando en cuenta que 5 representa el mayor puntaje y 1
                                    el menor puntaje
                                </strong>
                            </div>

                            <h5 className="my-5">
                                <u>1. Sobre el coach</u>
                            </h5>
                            {renderCoachQuestions()}

                            <h5 className="my-5">
                                <u>2. Sobre la sesión</u>
                            </h5>
                            {renderSessionQuestions()}

                            <h5 className="my-5">
                                <u>3. Sobre la logística del curso</u>
                            </h5>
                            {renderLogisticQuestions()}
                        </div>

                        <div className="tac mt-4">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Nombre de quién firma</label>
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control name-signature-input"
                                            value={state.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 mt-2">
                                    <div className="form-group">
                                        <label>Observaciones y comentarios</label>
                                        <textarea
                                            rows={5}
                                            name="comment"
                                            className="form-control name-signature-input"
                                            value={state.comment}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-12 tac">
                                    <div>
                                        <strong>Preview de la firma:</strong>
                                    </div>
                                    <button
                                        className="btn btn-primary mt-2"
                                        type="button"
                                        onClick={() => {
                                            setOpenSignature(true);
                                        }}
                                    >
                                        Firmar
                                    </button>
                                    <br />
                                    {state.signatureData && (
                                        <img
                                            src={state.signatureData}
                                            className="mt-1 center-50"
                                            alt="firma"
                                        />
                                    )}
                                </div>
                            </div>

                            <button className="btn btn-primary mt-4">Enviar</button>
                        </div>
                    </form>
                </div>
            );
        }
    };

    return (
        <div className="firma-page">
            <div className="container-signature pt-5">
                {loading ? (
                    <div className="center-sign-loader">
                        <Loader />
                    </div>
                ) : page === 'form' ? (
                    getSignForm()
                ) : (
                    <div className="full-center tac">
                        <FaIcons.FaCheckCircle className="confirm-sign-i" />

                        <h3 className="mt-3">Su califación se ha enviada con éxito</h3>
                    </div>
                )}
            </div>
            {openSignature && (
                <SignatureArea
                    close={() => {
                        setOpenSignature(false);
                    }}
                    save={saveSignature}
                />
            )}
        </div>
    );
};

export default withRouter(RateSession);
