import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { getData, handleError } from '../../functions/services';
import Loader from '../common/Loader';

const ChartMostUsedAreas = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState('');

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            const req = await getData('dashboard/areas', {});
            setData(req.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const renderChart = () => {
        let labelArray = [];
        let valueArray = [];

        data.forEach((val) => {
            labelArray.push(val.team.name);
            valueArray.push(val.SessionCount);
        });

        const options = {
            legend: {
                display: false,
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                ],
                xAxes: [
                    {
                        ticks: {
                            fontSize: 10,
                        },
                    },
                ],
            },
        };

        const chartData = {
            labels: labelArray,
            datasets: [
                {
                    label: 'Cantidad',
                    data: valueArray,
                    backgroundColor: [
                        'rgba(140, 115, 212, 0.2)',
                        'rgba(115, 212, 160, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(212, 115, 181,  0.2)',
                    ],
                    borderColor: [
                        'rgba(140, 115, 212, 1)',
                        'rgba(115, 212, 160, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(212, 115, 181,  1)',
                    ],
                    borderWidth: 1,
                },
            ],
        };

        return <Bar data={chartData} options={options} />;
    };

    return loading ? (
        <div className="center-flex">
            <Loader />
        </div>
    ) : (
        renderChart()
    );
};

export default ChartMostUsedAreas;
