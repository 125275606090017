import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import './assets/styles/main.scss';
import Dashboard from './Dashboard';
import Login from './pages/Login';
import Firma from './pages/Firma';
import NotFound from './pages/NotFound';
import AuthRoute from './auth/AuthRoute';
import NotAuthRoute from './auth/NotAutRoute';
import AnexFirma from './pages/AnexFirma';
import RateSession from './pages/RateSession';

function App() {
    return (
        <div className="app">
            <Router basename={'/'}>
                <div>
                    <Switch>
                        <Route exact path="/firmar-contrato/:uuid" component={Firma} />

                        <Route exact path="/calificar-session/:uuid" component={RateSession} />

                        <AuthRoute path="/admin" component={Dashboard} />

                        <NotAuthRoute exact path="/" component={Login} />

                        <Route component={NotFound} />
                    </Switch>
                </div>
            </Router>
        </div>
    );
}

export default App;
