import { format } from 'date-fns';

export const formatDateToLocal = (date) => {
    return format(fixDate(date), 'dd-MM-yyyy');
};

export const formatDateToAmerican = (date) => {
    return format(fixDate(date), 'yyyy-MM-dd');
};

const fixDate = (date) => {
    return new Date(`${date.substring(0, 10)} 00:00`);
};
