import React, { useEffect, useState } from 'react';
import { getData, handleError, postData, putData } from '../../functions/services';
import Loader from '../common/Loader';
import * as FaIcons from 'react-icons/fa';
import { formatDateToLocal } from '../../functions/dates';
import { coachQuestions, logisticQuestions, sessionQuestions } from '../../constants/data';

const ModalSessionRateDetails = ({ id }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState('');

    const loadData = async () => {
        try {
            const req = await getData(`sessionRates/resume/${id}`);
            setData(req.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const renderCoachQuestions = () => {
        return coachQuestions.map((val, i) => {
            return (
                <div key={i}>
                    <div className="my-2">
                        <strong>{val.text}:</strong>
                        <span className="float-right">{` ${parseFloat(
                            data.results[val.name]
                        ).toFixed(1)}`}</span>
                    </div>
                    <hr className="m-0" />
                </div>
            );
        });
    };

    const renderSessionQuestions = () => {
        return sessionQuestions.map((val, i) => {
            return (
                <div key={i}>
                    <div className="my-2">
                        <strong>{val.text}:</strong>
                        <span className="float-right">{` ${parseFloat(
                            data.results[val.name]
                        ).toFixed(1)}`}</span>
                    </div>
                    <hr className="m-0" />
                </div>
            );
        });
    };

    const renderLogisticQuestions = () => {
        return logisticQuestions.map((val, i) => {
            return (
                <div key={i}>
                    <div className="my-2">
                        <strong>{val.text}:</strong>
                        <span className="float-right">{` ${parseFloat(
                            data.results[val.name]
                        ).toFixed(1)}`}</span>
                    </div>
                    <hr className="m-0" />
                </div>
            );
        });
    };

    const renderComments = () => {
        return data.comments.map((val, i) => {
            return (
                <div className="rate-comment mt-4" key={i}>
                    <strong>{val.name}:</strong> {val.comment}
                </div>
            );
        });
    };

    const renderDetails = () => {
        if (!data.results) {
            return <h4 className="tac mt-4">Aun no se reciben calificaciones en esta sesión</h4>;
        }
        return (
            <>
                <div className="row mt-3">
                    <div className="col-md-4">
                        <strong>Calificacion general:</strong> {data.generalProm}
                    </div>
                    <div className="col-md-4">
                        <strong>Calificaciones recibidas:</strong> {data.comments.length}
                    </div>
                </div>

                <hr />

                <div>
                    <h5 className="mt-4">
                        <u>Promedio por preguntas:</u>
                    </h5>
                </div>

                <div className="rate-question-section my-5">
                    <h5 className="mb-5">
                        <u>1.Consolidado sobre el coach/mentor</u>
                        <span className="float-right">{data.coachProm}</span>
                    </h5>

                    {renderCoachQuestions()}
                </div>

                <div className="rate-question-section my-5">
                    <h5 className="mb-5">
                        <u>2.Consolidado sobre la sesión</u>
                        <span className="float-right">{data.sessionProm}</span>
                    </h5>

                    {renderSessionQuestions()}
                </div>

                <div className="rate-question-section my-5">
                    <h5 className="mb-5">
                        <u>3.Consolidado sobre la logistica</u>
                        <span className="float-right">{data.logisticProm}</span>
                    </h5>

                    {renderLogisticQuestions()}
                </div>

                <div>
                    <h5 className="mt-4">
                        <u>Comentarios:</u>
                    </h5>
                </div>

                {renderComments()}
            </>
        );
    };

    return (
        <div>
            <div className="p-2 modal-title">Calificaciones de los coachees/mentees</div>

            {loading ? (
                <div className="center-admin-loader-modal">
                    <Loader />
                </div>
            ) : (
                renderDetails()
            )}
        </div>
    );
};

export default ModalSessionRateDetails;
