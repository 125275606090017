import React from 'react'

const NotFound = () => {
    return (
        <div className='min-vh-100'>
            <div className="full-center">
                <h2>No se encontro la página</h2>
            </div>
        </div>
    )
}

export default NotFound
