import { Doughnut } from 'react-chartjs-2';

const ChartDonut = ({ data, chartColors, labels }) => {
    const renderChart = () => {
        const options = {
            legend: {
                display: true,
            },
        };

        const chartData = {
            labels: labels,
            datasets: [
                {
                    label: '# of Votes',
                    data: data,
                    backgroundColor: chartColors.backgroundColors,
                    borderColor: chartColors.borderColors,
                    borderWidth: 1,
                },
            ],
        };

        return <Doughnut data={chartData} options={options} />;
    };

    return renderChart();
};

export default ChartDonut;
