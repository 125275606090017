import React, { useState, useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import ModalAnex from '../components/modal/ModalAnex';
import DataTable from 'react-data-table-component';
import { sweetAlertToast, sweetQuestion } from '../functions/sweetAlert';
import { deleteData, getData, handleError } from '../functions/services';
import * as FaIcons from 'react-icons/fa';
import Loader from '../components/common/Loader';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getUrlAnex } from '../functions/contract';
import { withRouter } from 'react-router-dom';
import { formatDateToLocal } from '../functions/dates';

const PendingAnex = (props) => {
    const [openAnexM, setOpenAnexM] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [modalData, setModalData] = useState('');

    const {
        match: {
            params: { id },
        },
    } = props;

    const onOpenAnex = (data) => {
        setModalData(data);
        setOpenAnexM(true);
    };
    const onCloseContract = () => setOpenAnexM(false);

    const loadTable = async () => {
        try {
            setLoading(true);
            const req = await getData(`anex/${id}/pending`, {});
            setLoading(false);
            setData(req.data);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const tableData = () => {
        const columns = [
            {
                name: 'Fecha',
                selector: 'date',
                sortable: true,
            },
            {
                name: 'Acciones',
                selector: 'actions',
            },
        ];

        let tableArray = [];

        searchArray().forEach((val) => {
            const json = {
                date: formatDateToLocal(val.createdAt),
                actions: (
                    <>
                        <span
                            className="pointer"
                            onClick={() => onOpenAnex(val)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="editar"
                        >
                            <FaIcons.FaPencilAlt className="table-icon" />
                        </span>
                        <span
                            className="pointer"
                            onClick={() => onDelete(val.id)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="eliminar"
                        >
                            <FaIcons.FaTrash className="table-icon" />
                        </span>
                        <br />
                        <span
                            className="pointer"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="copiar url"
                        >
                            <CopyToClipboard
                                text={getUrlAnex(val.uuid)}
                                onCopy={() =>
                                    sweetAlertToast(
                                        `URL para el cliente ${val.contract.clientName} copiada`
                                    )
                                }
                            >
                                <FaIcons.FaCopy className="table-icon" />
                            </CopyToClipboard>
                        </span>
                    </>
                ),
            };

            tableArray.push(json);
        });

        return <DataTable noHeader pagination columns={columns} data={tableArray} />;
    };

    const onDelete = async (id) => {
        const question = await sweetQuestion('Quieres eliminar este anexo?', null);
        if (question.isConfirmed) {
            try {
                await deleteData('anex', id);
                loadTable();
            } catch (err) {
                console.log(err);
            }
        }
    };

    const searchArray = () => {
        const dataCopy = [...data];
        return dataCopy;
    };

    useEffect(() => {
        loadTable();
    }, []);

    return (
        <div className="mt-4">
            <div className="d-flex mb-1">
                <button className="btn btn-primary ml-auto" onClick={() => onOpenAnex('')}>
                    Crear Anexo
                </button>
            </div>

            {loading ? (
                <div className="center-admin-loader-page">
                    <Loader />
                </div>
            ) : (
                tableData()
            )}

            <Modal
                classNames={{ modal: 'modal-size' }}
                open={openAnexM}
                onClose={onCloseContract}
                center
            >
                <ModalAnex
                    data={modalData}
                    contract={id}
                    close={onCloseContract}
                    loadTable={loadTable}
                />
            </Modal>
        </div>
    );
};

export default withRouter(PendingAnex);
