import React, { useState } from 'react';
import { handleError, postData, putData } from '../../functions/services';
import Loader from '../common/Loader';
import useForm from '../../hooks/useForm';
import empty from '../../assets/images/empty.jpeg';

const ModalUpdatePassword = (props) => {
    const { data } = props;

    const { state, handleChange } = useForm({
        password: '',
        passwordConfirmation: '',
    });

    const [loading, setLoading] = useState(false);

    const edit = async () => {
        setLoading(true);

        let formData = new FormData();

        Object.keys(state).forEach((key) => {
            formData.append(key, state[key]);
        });

        try {
            await putData('auth/update-password', data.id, formData, {});
            props.close();
            props.loadTable();
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        edit();
    };

    return (
        <div>
            <div className="p-2 modal-title">Actualizar contraseña de {data.name}</div>

            {loading ? (
                <div className="center-admin-loader-modal">
                    <Loader />
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="form-row mt-3">
                        <div className="col-12">
                            <div className="form-group">
                                <label>Contraseña</label>
                                <input
                                    type="password"
                                    name="password"
                                    className="form-control"
                                    value={state.password}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label>Confirmar contraseña</label>
                                <input
                                    type="password"
                                    name="passwordConfirmation"
                                    className="form-control"
                                    value={state.passwordConfirmation}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <button className="btn btn-primary" type="submit" disabled={loading}>
                        Guardar
                    </button>
                </form>
            )}
        </div>
    );
};

export default ModalUpdatePassword;
