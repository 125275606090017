import React, { useEffect, useState } from 'react';
import { getData, handleError } from '../../functions/services';
import Loader from '../common/Loader';
import * as FaIcons from 'react-icons/fa';

const ModalClientsRanking = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            const req = await getData('reports/clients-ranking', {});
            setData(req.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const renderTable = () => {
        const tableData = data.map((val, i) => {
            return (
                <tr key={i}>
                    <td>{val.name}</td>
                    <td className="tac">{val.sessionCount}</td>
                    <td className="tac">{i + 1}</td>
                </tr>
            );
        });

        return (
            <>
                <table className="table table-striped">
                    <thead className="tac">
                        <tr>
                            <th>Nombre</th>
                            <th>Sesiones</th>
                            <th>#</th>
                        </tr>
                    </thead>
                    <tbody>{tableData}</tbody>
                </table>
            </>
        );
    };

    return (
        <div>
            <div className="p-2 modal-title">
                Ranking de clientes
                <FaIcons.FaCrown className="float-right" />
            </div>

            {loading ? (
                <div className="center-flex">
                    <Loader />
                </div>
            ) : (
                renderTable()
            )}
        </div>
    );
};

export default ModalClientsRanking;
