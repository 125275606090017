import React, { useState } from 'react';
import { handleError, postData, putData } from '../../functions/services';
import Loader from '../common/Loader';
import useForm from '../../hooks/useForm';
import empty from '../../assets/images/empty.jpeg';

const ModalCoach = (props) => {
    const { data } = props;

    const { state, handleChange, handleChangeByName } = useForm({
        name: data ? data.name : '',
        email: data ? data.email : '',
        password: '',
        passwordConfirmation: '',
        image: '',
    });

    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState(data ? data.photo : '');

    const handleImage = (e) => {
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            setPreview(reader.result);
            handleChangeByName('image', reader.result);
        };
    };

    const create = async () => {
        setLoading(true);

        let formData = new FormData();

        Object.keys(state).forEach((key) => {
            formData.append(key, state[key]);
        });

        try {
            await postData('auth/signup-coach', formData, {});
            props.close();
            props.loadTable();
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const edit = async () => {
        setLoading(true);

        let formData = new FormData();

        Object.keys(state).forEach((key) => {
            formData.append(key, state[key]);
        });

        try {
            await putData('users', data.id, formData, {});
            props.close();
            props.loadTable();
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (data) {
            edit();
        } else {
            create();
        }
    };

    return (
        <div>
            <div className="p-2 modal-title">Coach/Mentor</div>

            {loading ? (
                <div className="center-admin-loader-modal">
                    <Loader />
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="form-row mt-3">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    value={state.email}
                                    onChange={(e) => handleChange(e)}
                                    required
                                    disabled={data}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Nombre</label>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    value={state.name}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />
                            </div>
                        </div>
                        {!data && (
                            <>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Contraseña</label>
                                        <input
                                            type="password"
                                            name="password"
                                            className="form-control"
                                            value={state.password}
                                            onChange={(e) => handleChange(e)}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Confirmar contraseña</label>
                                        <input
                                            type="password"
                                            name="passwordConfirmation"
                                            className="form-control"
                                            value={state.passwordConfirmation}
                                            onChange={(e) => handleChange(e)}
                                            required
                                        />
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="col-6">
                            <div className="form-group">
                                <label>Foto</label>
                                <input
                                    type="file"
                                    name="image"
                                    className="form-control"
                                    accept="image/*"
                                    onChange={handleImage}
                                />
                            </div>
                        </div>

                        <div className="col-6"></div>

                        <div className="col-6">
                            <img
                                src={preview ? preview : empty}
                                alt="foto de perfil"
                                className="center-50"
                            />
                        </div>
                    </div>

                    <button className="btn btn-primary" type="submit" disabled={loading}>
                        Guardar
                    </button>
                </form>
            )}
        </div>
    );
};

export default ModalCoach;
