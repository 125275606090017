import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import { SidebarData } from './SidebarData';
import logo from '../assets/images/logo.png';
import { getData, getUserData, handleError, setUserData } from '../functions/services';
import { canShowMenuItem } from '../functions/navbar';
import Modal from 'react-responsive-modal';
import ModalUpdatePassword from './modal/ModalUpdatePassword';

const Navbar = (props) => {
    const [sidebar, setsidebar] = useState(true);
    const [openModalPassword, setOpenModalPassword] = useState(false);
    const [data, setData] = useState('');

    const onOpenModalPassword = () => {
        setOpenModalPassword(true);
    };
    const onCloseModalPassword = () => setOpenModalPassword(false);

    const handleSide = () => {
        setsidebar(!sidebar);

        const page = document.getElementById('main-page');

        if (page.classList.contains('active-padding')) {
            page.classList.remove('active-padding');
            page.classList.add('inactive-padding');
        } else {
            page.classList.add('active-padding');
            page.classList.remove('inactive-padding');
        }

        const navBars = document.getElementById('nav-bars');

        if (navBars.classList.contains('active-padding-nav')) {
            navBars.classList.remove('active-padding-nav');
            navBars.classList.add('inactive-padding-nav');
        } else {
            navBars.classList.add('active-padding-nav');
            navBars.classList.remove('inactive-padding-nav');
        }

        const navLogo = document.getElementById('nav-logo');

        if (navLogo.classList.contains('active-padding-nav')) {
            navLogo.classList.remove('active-padding-nav');
            navLogo.classList.add('inactive-padding-nav');
        } else {
            navLogo.classList.add('active-padding-nav');
            navLogo.classList.remove('inactive-padding-nav');
        }
    };

    const sideList = () => {
        return SidebarData.filter((val) => canShowMenuItem(val)).map((val, i) => (
            <li key={i} className={val.class}>
                <Link to={val.path}>
                    <div class="nav_icon_small">{val.icon}</div>
                    <div class="nav_title">
                    <span>{val.title}</span>
                    </div>
                </Link>
            </li>
        ));
    };

    const logout = () => {
        setUserData('');
        props.history.push('/');
    };

    const loadData = async () => {
        if (!getUserData()) {
            return;
        }

        try {
            const req = await getData(`sessionItems/resume/${getUserData().user.id}`);
            setData(req.data);
            setTimeout(() => {
                loadData();
            }, 5000);
        } catch (error) {
            console.log(error);
            setTimeout(() => {
                loadData();
            }, 5000);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const renderResume = () => {
        if (!data) {
            return;
        }

        return (
            <div className="toast  show toast-session" role="alert" aria-live="assertive" >              
              <div class="toast-header">
                <button type="button" className="btn btn-outline-danger btn-sm">Total <span class="badge badge-light">{` ${data.total}`}</span></button>
                <button type="button" className="btn btn-outline-danger btn-sm">Pendientes <span class="badge badge-light">{` ${data.pending}`}</span></button>
                <button type="button" className="btn btn-outline-danger btn-sm">Completadas <span class="badge badge-light">{` ${data.completed}`}</span></button>                
              </div>
            </div>
        );
    };

    return (
        <>
            {renderResume()}

            <div className="navbar">
                <img
                    src={logo}
                    alt="logo de zendero"
                    className="nav-logo active-padding-nav pointer"
                    id="nav-logo"
                    onClick={handleSide}
                />

                <Link
                    to="#"
                    className="menu-bars active-padding-nav"
                    onClick={handleSide}
                    id="nav-bars"
                >
                    <FaIcons.FaBars className="open-nav-icon" />
                </Link>

                <div className="dropdown profile-position">
                    
                    <span className="nav-user-name">{getUserData().user.name}</span>
                    <button
                        className="btn dropdown-toggle profile-button"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <FaIcons.FaUserCircle />
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button className="dropdown-item" href="#" onClick={onOpenModalPassword}>
                            Cambiar contraseña
                        </button>
                        <button className="dropdown-item" href="#" onClick={logout}>
                            Salir
                        </button>
                    </div>                    

                </div>
            </div>
            <div id="side-menu" className={sidebar ? 'nav-menu active' : 'nav-menu close'}>
                <ul className="nav-menu-items" >
                    <li className="navbar-toggle">
                        <img src={logo} alt="logo" className="nav-logo" />
                    </li>                    
                    <div class="litems">
                    {sideList()}
                    </div>
                </ul>
                    
            </div>
            <Modal
                classNames={{ modal: 'modal-size' }}
                open={openModalPassword}
                onClose={onCloseModalPassword}
                center
            >
                <ModalUpdatePassword
                    data={getUserData().user}
                    close={onCloseModalPassword}
                    loadTable={() => {}}
                />
            </Modal>

            

        </>
    );
};

export default withRouter(Navbar);
