import axios from 'axios';
import { sweetError } from './sweetAlert';

const url = 'https://coach.avssmart.com/api/';
//const url = 'http://localhost:3000/api/';

export const postData = (endpoint, data, config = {}) => {
    return axios.post(url + endpoint, data, config);
};

export const getData = (endpoint, config = {}) => {
    return axios.get(url + endpoint, config);
};

export const deleteData = (endpoint, id, config = {}) => {
    return axios.delete(url + endpoint + '/' + id, config);
};
export const putData = (endpoint, id, data = {}, config = {}) => {
    return axios.put(url + endpoint + '/' + id, data, config);
};

export const allData = (array) => {
    return axios.all(array);
};

export const handleError = (error) => {
    if (error.response) {
        sweetError('Error:', `${error.response.data}`);
    } else {
        sweetError('Error:', ' No hay respuesta del servidor');
    }
};

export const setUserData = (val) => {
    localStorage.setItem('UserData', JSON.stringify(val));
};

export const getUserData = (val) => {
    return JSON.parse(localStorage.getItem('UserData'));
};
