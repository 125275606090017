export const getTotalPagoUni = (data = []) => {
    let total = 0;

    data.forEach((val) => {
        const { instala, venta, actv, cantidad } = val;

        total += (parseFloat(instala) + parseFloat(venta) + parseFloat(actv)) * parseFloat(cantidad);
    });

    return total || 0;
};

export const getTotalMen = (data = []) => {
    let total = 0;

    data.forEach((val) => {
        const { arrien, plan, seguro, cantidad } = val;

        total += (parseFloat(arrien) + parseFloat(plan) + parseFloat(seguro)) * parseFloat(cantidad);
    });

    return total || 0;
};

export const getUrl = (uuid) => {
    return window.location.origin + '/firmar-contrato/' + uuid;
};

export const getUrlAnex = (uuid) => {
    return window.location.origin + '/firmar-anexo/' + uuid;
};
