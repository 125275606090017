import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { getData, handleError } from '../../functions/services';
import Loader from '../common/Loader';

const ChartProyectCumpliment = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState('');

    const loadData = async () => {
        try {
            const req = await getData('dashboard/proyect-cumpliment', {});
            setData(req.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const renderChart = () => {
        const options = {
            legend: {display: false,position: "left"},
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                ],
            },
        };

        const chartData = {
            labels: ['0-25%', '26-50%', '51-75%', '76-100%'],
            datasets: [
                {
                    label: 'Cantidad',
                    data: [data.block_0_25, data.block_26_50, data.block_51_75, data.block_76_100],
                    backgroundColor: [
                        'rgba(255, 159, 64, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 159, 64, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(75, 192, 192, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        };

        return <Bar data={chartData} options={options} />;
    };

    return loading ? (
        <div className="center-flex">
            <Loader />
        </div>
    ) : (
        renderChart()
    );
};

export default ChartProyectCumpliment;
