import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Loader from '../components/common/Loader';
import { deleteData, getData, handleError } from '../functions/services';
import DataTable from 'react-data-table-component';
import * as FaIcons from 'react-icons/fa';
import { sweetQuestion } from '../functions/sweetAlert';
import ModalCompany from '../components/modal/ModalCompany';
import ModalTeam from '../components/modal/ModalTeam';
import ModalMentee from '../components/modal/ModalMentee';
import ModalCoaching from '../components/modal/ModalCoaching';
import { formatDateToLocal } from '../functions/dates';
import { Link } from 'react-router-dom';
import { customTableStyles } from '../constants/data';

const AdminCompletedCoaching = () => {
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [modalData, setModalData] = useState('');

    const onOpenModal = (data) => {
        setModalData(data);
        setOpenModal(true);
    };
    const onCloseModal = () => setOpenModal(false);

    const loadTable = async () => {
        try {
            setLoading(true);
            const req = await getData('sessions/completed', {});
            setLoading(false);
            setData(req.data);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const tableData = () => {
        const columns = [
            {
                name: 'Cliente',
                selector: 'company',
                sortable: true,
            },
            {
                name: 'Sesiones',
                selector: 'number',
                sortable: true,
                center: true,
            },
            {
                name: 'País',
                selector: 'country',
                sortable: true,
            },
            {
                name: 'Fecha',
                selector: 'createdAt',
                sortable: true,
                center: true,
            },
            {
                name: 'Acciones',
                selector: 'actions',
                center: true,
            },
        ];

        let tableArray = [];

        searchArray().forEach((val) => {
            const json = {
                id: val.id,
                company: val.company.name,
                number: val.number,
                country: val.country,
                createdAt: formatDateToLocal(val.createdAt),
                actions: (
                    <>
                        <span
                            className="pointer"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Ver sesiones"
                        >
                            <Link to={`/admin/programa-pendiente/${val.id}`}>
                                <FaIcons.FaChalkboardTeacher className="table-icon chalkboard-icon" />
                            </Link>
                        </span>
                    </>
                ),
            };

            tableArray.push(json);
        });

        return (
            <DataTable
                customStyles={customTableStyles}
                noHeader
                pagination
                columns={columns}
                data={tableArray}
            />
        );
    };

    const onDelete = async (id) => {
        const question = await sweetQuestion('Quieres eliminar este proeyecto?', null);
        if (question.isConfirmed) {
            try {
                await deleteData('sessions', id);
                loadTable();
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
    };

    const searchArray = () => {
        let keys = ['country'];
        const dataCopy = [...data];

        return dataCopy.filter((value) => {
            return keys.reduce((acum, key) => {
                return acum || value[key].toString().toLowerCase().includes(search.toLowerCase());
            }, false);
        });
    };

    useEffect(() => {
        loadTable();
    }, []);

    return (
        <div>
            <div className="container-page ">
                <div className="page-title">Proyectos Completados</div>

                <div className="card p-3 mt-3 position-static">
                    <div className="d-flex mb-1">
                        <input
                            type="text"
                            className="form-control page-search mr-auto"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>

                    {loading ? (
                        <div className="center-admin-loader-page">
                            <Loader />
                        </div>
                    ) : (
                        tableData()
                    )}
                </div>
            </div>

            <Modal
                classNames={{ modal: 'modal-size' }}
                open={openModal}
                onClose={onCloseModal}
                center
            >
                <ModalCoaching data={modalData} close={onCloseModal} loadTable={loadTable} />
            </Modal>
        </div>
    );
};

export default AdminCompletedCoaching;
