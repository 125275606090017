import React, { useEffect, useState } from 'react';
import { formatDateToLocal } from '../../functions/dates';
import { getData, handleError } from '../../functions/services';
import Loader from '../common/Loader';

const ModalDelayedProyects = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState('');

    const loadData = async () => {
        try {
            const req = await getData('reports/late-proyects', {});
            setData(req.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const renderContent = () => {
        return data.map((val) => {
            return (
                <div class="card" key={val.id}>
                    <div class="card-header" id={`heading${val.id}`}>
                        <h5 class="mb-0">
                            <button
                                class="btn btn-link"
                                data-toggle="collapse"
                                data-target={`#collapse${val.id}`}
                                aria-expanded="true"
                                aria-controls={`collapse${val.id}`}
                            >
                                {`${val.company.name}-${val.city}-${formatDateToLocal(
                                    val.createdAt
                                )}`}
                            </button>
                        </h5>
                    </div>

                    <div
                        id={`collapse${val.id}`}
                        class="collapse"
                        aria-labelledby={`heading${val.id}`}
                        data-parent="#accordion"
                    >
                        <div class="card-body">
                            <ul className="mb-0">
                                {val.SessionItem.map((item) => {
                                    return (
                                        <li key={item.id}>
                                            {`#${item.number}-${item.sessionType}-${
                                                item.user.name
                                            }-${formatDateToLocal(item.date)}`}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div>
            <div className="p-2 modal-title">Proyectos demorados</div>

            {loading ? (
                <div className="center-admin-loader-modal">
                    <Loader />
                </div>
            ) : (
                <>
                    <div id="accordion" className="mt-4">
                        {renderContent()}
                    </div>
                </>
            )}
        </div>
    );
};

export default ModalDelayedProyects;
