import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import Loader from '../components/common/Loader';
import { getData, handleError } from '../functions/services';
import AdminPendingCoachingItems from '../components/AdminPendingCoachingItems';
import AdminCompleteCoachingItems from '../components/AdminCompleteCoachingItems';
import { sessionStatus } from '../constants/data';

const AdminCoachingItems = (props) => {
    const {
        match: {
            params: { id },
        },
    } = props;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState('');

    const loadData = async () => {
        try {
            setLoading(true);
            const req = await getData(`sessions/${id}`, {});
            setLoading(false);
            setData(req.data);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div>
            <div className="container-page ">
                {loading ? (
                    <div className="center-admin-loader-page">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <div className="page-title">
                            {`Sesiones de coaching del proyecto del cliente 
                            ${data && data.company.name} - ${data && data.city}`}
                        </div>

                        <div className="card p-3 mt-3 position-static">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                {data.sessionStatus === sessionStatus.pending && (
                                    <li className="nav-item">
                                        <a
                                            className="nav-link active"
                                            id="home-tab"
                                            data-toggle="tab"
                                            href="#home"
                                            role="tab"
                                            aria-controls="home"
                                            aria-selected="true"
                                        >
                                            Pendientes
                                        </a>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${
                                            data.sessionStatus === sessionStatus.completed &&
                                            'active'
                                        }`}
                                        id="profile-tab"
                                        data-toggle="tab"
                                        href="#profile"
                                        role="tab"
                                        aria-controls="profile"
                                        aria-selected="false"
                                    >
                                        Completadas
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                {data.sessionStatus === sessionStatus.pending && (
                                    <div
                                        className="tab-pane fade show active"
                                        id="home"
                                        role="tabpanel"
                                        aria-labelledby="home-tab"
                                    >
                                        <AdminPendingCoachingItems />
                                    </div>
                                )}

                                <div
                                    className={`nav-link ${
                                        data.sessionStatus === sessionStatus.completed
                                            ? 'show active'
                                            : 'fade'
                                    }`}
                                    id="profile"
                                    role="tabpanel"
                                    aria-labelledby="profile-tab"
                                >
                                    <AdminCompleteCoachingItems />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default AdminCoachingItems;
