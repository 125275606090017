import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { getData, handleError } from '../../functions/services';
import Loader from '../common/Loader';

const ChartSessionDistribution = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState('');

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            const req = await getData('dashboard/session-types', {});
            setData(req.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const renderChart = () => {
        let labelArray = [];
        let valueArray = [];

        data.forEach((val) => {
            labelArray.push(val.sessionType);
            valueArray.push(val.SessionCount);
        });

        const options = {
            legend: {
                display: true,position: "left"
            },
        };

        const chartData = {
            labels: labelArray,
            datasets: [
                {
                    data: valueArray,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        ,
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        };

        return <Pie data={chartData} options={options} />;
    };

    return loading ? (
        <div className="center-flex">
            <Loader />
        </div>
    ) : (
        renderChart()
    );
};

export default ChartSessionDistribution;
