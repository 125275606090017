import { Bar } from 'react-chartjs-2';

const ChartBar = ({ data, chartColors, labels, label }) => {
    const renderChart = () => {
        const options = {
            legend: {
                display: false,
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                ],
            },
        };

        const chartData = {
            labels: labels,
            datasets: [
                {
                    label: label,
                    data: data,
                    backgroundColor: chartColors.backgroundColors,
                    borderColor: chartColors.borderColors,
                    borderWidth: 1,
                },
            ],
        };

        return <Bar data={chartData} options={options} />;
    };

    return renderChart();
};

export default ChartBar;
