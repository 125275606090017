import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Loader from './common/Loader';
import { deleteData, getData, handleError } from '../functions/services';
import DataTable from 'react-data-table-component';
import * as FaIcons from 'react-icons/fa';
import { sweetAlertToast, sweetQuestion } from '../functions/sweetAlert';
import { formatDateToLocal } from '../functions/dates';
import { Link, withRouter } from 'react-router-dom';
import ModalSession from './modal/ModalSession';
import ModalSessionDetails from './modal/ModalSessionDetails';
import { customTableStyles } from '../constants/data';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getRateSessionURL } from '../functions/sessions';
import ModalSessionRateDetails from './modal/ModalSessionRateDetails';

const AdminCompletedCoachingItems = (props) => {
    const {
        match: {
            params: { id },
        },
    } = props;

    const [openModal, setOpenModal] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [openModalRate, setOpenModalRate] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [modalData, setModalData] = useState('');

    const onOpenModal = (data) => {
        setModalData(data);
        setOpenModal(true);
    };

    const onOpenModalEdit = (data) => {
        setModalData(data);
        setOpenModalEdit(true);
    };

    const onOpenModalRate = (data) => {
        setModalData(data);
        setOpenModalRate(true);
    };

    const onCloseModal = () => setOpenModal(false);
    const onCloseModalEdit = () => setOpenModalEdit(false);
    const onCloseModalRate = () => setOpenModalRate(false);

    const loadTable = async () => {
        try {
            setLoading(true);
            const req = await getData(`sessionItems/completed/${id}`);
            setLoading(false);
            setData(req.data);
        } catch (error) {
            console.log(error);
            handleError(error);
        }
    };

    const tableData = () => {
        const columns = [
            {
                name: 'Fecha',
                selector: 'date',
                center: true,
            },
            {
                name: 'Ciudad',
                selector: 'city',
                sortable: true,
            },
            {
                name: 'Coach/Mentor',
                selector: 'coach',
                sortable: true,
            },
            {
                name: 'Área',
                selector: 'team',
                sortable: true,
            },
            {
                name: 'Horario',
                selector: 'hour',
                center: true,
            },
            {
                name: 'Tipo',
                selector: 'type',
                sortable: true,
            },
            {
                name: 'Acciones',
                selector: 'actions',
                center: true,
            },
        ];

        let tableArray = [];

        searchArray().forEach((val) => {
            const json = {
                id: val.id,
                date: formatDateToLocal(val.date),
                city: val.city,
                coach: val.user.name,
                team: val.team.name,
                hour: `${val.hourIn} - ${val.hourOut}`,
                type: val.sessionType,
                actions: (
                    <>
                        <span
                            className="pointer"
                            onClick={() => onOpenModal(val)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Ver detalles"
                        >
                            <FaIcons.FaEye className="table-icon" />
                        </span>
                        <span
                            className="pointer"
                            onClick={() => onOpenModalEdit(val)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Editar"
                        >
                            <FaIcons.FaPencilAlt className="table-icon" />
                        </span>
                        <span
                            className="pointer"
                            onClick={() => onOpenModalRate(val)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Ver calificaciones de coaches/mentees"
                        >
                            <FaIcons.FaStar className="table-icon" />
                        </span>
                        <span
                            className="pointer"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="copiar url"
                        >
                            <CopyToClipboard
                                text={getRateSessionURL(val.uuid)}
                                onCopy={() => sweetAlertToast(`URL para la calificación copiada`)}
                            >
                                <FaIcons.FaCopy className="table-icon" />
                            </CopyToClipboard>
                        </span>
                    </>
                ),
            };

            tableArray.push(json);
        });

        return (
            <DataTable
                customStyles={customTableStyles}
                noHeader
                pagination
                columns={columns}
                data={tableArray}
            />
        );
    };

    const onDelete = async (id) => {
        const question = await sweetQuestion('Quieres eliminar este proyecto?', null);
        if (question.isConfirmed) {
            try {
                await deleteData('sessions', id);
                loadTable();
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
    };

    const searchArray = () => {
        let keys = ['participantsNumber'];
        const dataCopy = [...data];

        return dataCopy.filter((value) => {
            return keys.reduce((acum, key) => {
                return acum || value[key].toString().toLowerCase().includes(search.toLowerCase());
            }, false);
        });
    };

    useEffect(() => {
        loadTable();
    }, []);

    return (
        <div className="mt-2">
            <div className="d-flex mb-1"></div>

            {loading ? (
                <div className="center-admin-loader-page">
                    <Loader />
                </div>
            ) : (
                tableData()
            )}

            <Modal
                classNames={{ modal: 'modal-size' }}
                open={openModal}
                onClose={onCloseModal}
                center
            >
                <ModalSessionDetails data={modalData} />
            </Modal>

            <Modal
                classNames={{ modal: 'modal-size' }}
                open={openModalRate}
                onClose={onCloseModalRate}
                center
            >
                <ModalSessionRateDetails id={modalData.id} />
            </Modal>

            <Modal
                classNames={{ modal: 'modal-size' }}
                open={openModalEdit}
                onClose={onCloseModalEdit}
                center
            >
                <ModalSession
                    data={modalData}
                    sessionId={id}
                    close={onCloseModalEdit}
                    loadTable={loadTable}
                />
            </Modal>
        </div>
    );
};

export default withRouter(AdminCompletedCoachingItems);
